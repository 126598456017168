import * as React from "react";
import {
  Box,
  Card,
  CardContent,
  CardCover,
  FormLabel,
  Typography,
  Button,
} from "@mui/joy";
import { useDropzone } from "react-dropzone";
import { FaUpload, FaTrashAlt } from "react-icons/fa";
import { isMobile } from "react-device-detect";

type ImagePickeProps = {
  value: File[];
  onChange: (values: File[]) => void;
};
const ImagePicker = ({ value, onChange }: ImagePickeProps) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: (files: File[]) => onChange(value.concat(files)),
  });

  const dropLabel = isMobile
    ? "Cliquez ici pour ajouter des photos"
    : "Déplacez les images ici";

  const handleDelete = (imageName: string) => {
    const newArray = value.filter((e) => e.name != imageName);
    onChange(newArray);
  };

  return (
    <>
      <FormLabel>Photos</FormLabel>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            justifyContent: "center",
          }}
        >
          {value.map((image) => (
            <Card
              key={image.name}
              variant="outlined"
              sx={{
                height: 150,
                maxWidth: 250,
                flexGrow: 1,
                "&:hover": {
                  boxShadow: "md",
                  borderColor: "neutral.outlinedHoverBorder",
                },
              }}
            >
              <CardCover>
                <img
                  alt={"la photo du produit"}
                  loading={"lazy"}
                  src={URL.createObjectURL(image)}
                />
              </CardCover>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "end",
                }}
              >
                <Button
                  onClick={() => handleDelete(image.name)}
                  color={"danger"}
                  size={"sm"}
                  variant={"solid"}
                >
                  <FaTrashAlt />
                </Button>
              </CardContent>
            </Card>
          ))}
        </Box>
        <Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              border: "1px dashed #5fa9e0",
              borderRadius: "20px",
              minHeight: "6em",
            }}
            {...getRootProps({ className: "dropzone" })}
          >
            <input {...getInputProps()} />
            <Typography startDecorator={<FaUpload />} level={"body-md"}>
              {dropLabel}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ImagePicker;
