import * as React from "react";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Typography,
} from "@mui/joy";
import ImagePicker from "./sub/ImagePicker";
import { WeightSlider } from "./WeightSlider";
import { PlatformSelector } from "./PlatformSelector";
import { FaPaperPlane } from "react-icons/fa";
import { useStorageService } from "../../../../services/StorageService";
import { Alert, Backdrop, LinearProgress, Snackbar } from "@mui/material";
import * as Task from "fp-ts/Task";
import * as ArrayFP from "fp-ts/Array";
import { pipe } from "fp-ts/function";
import { useNavigate } from "react-router-dom";
import { useFirestore } from "../../../../services/FirestoreService";
import {
  ClassifiedAdDocument,
  ClassifiedAdStatusEnum,
  PlatformEnum,
  UpdateClassifiedAddObject,
} from "../../../../types/Types";
import { v4 as uuidv4 } from "uuid";
import ImageViewerComponent from "./sub/ImageViewComponent";
import { Timestamp } from "firebase/firestore";

type Inputs = {
  title: string;
  description: string;
  price: number;
  photo: File[];
  weight: number;
  platform: PlatformEnum;
};

const classifiedAdToInputs = (ad?: ClassifiedAdDocument): Inputs => {
  if (ad !== undefined) {
    return {
      title: ad.title,
      description: ad.description,
      price: ad.price,
      photo: [],
      weight: ad.weight,
      platform: ad.platform,
    };
  } else {
    return {
      title: "",
      description: "",
      price: 0,
      photo: [],
      weight: 2,
      platform: PlatformEnum.LEBONCOIN,
    };
  }
};

type ProgressComponentProps = {
  progress: number[];
};
const ProgressComponent = (props: ProgressComponentProps) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const progressSum = props.progress.reduce((a, b) => a + b, 0);
    const percent = Math.round(progressSum / props.progress.length);
    setProgress(percent);
  }, [props.progress]);

  return (
    <Card sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography level={"h3"}>Sauvegarde en cours</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
        }}
      >
        {props.progress.length === 0 ? (
          <LinearProgress sx={{ flex: 4 }} />
        ) : (
          <>
            <LinearProgress
              sx={{ flex: 4 }}
              variant="determinate"
              value={progress}
            />
            <Typography sx={{ flex: 1 }} level={"body-md"}>
              {progress} %
            </Typography>
          </>
        )}
      </Box>
    </Card>
  );
};

type AddClassifiedAddFormProps = {
  onCancel: () => void;
  isNewClassifiedAdd: boolean;
  initialValue?: ClassifiedAdDocument;
};

const ClassifiedAdForm = ({
  onCancel,
  isNewClassifiedAdd,
  initialValue,
}: AddClassifiedAddFormProps) => {
  const { control, register, handleSubmit } = useForm<Inputs>({
    defaultValues: classifiedAdToInputs(initialValue),
  });
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [errorSnackBarMessage, setErrorSnackBarMessage] = useState<
    string | null
  >(null);
  const navigate = useNavigate();
  const { uploadPhoto } = useStorageService();
  const [uploadProgressArray, setUploadProgressArray] = useState<number[]>([]);
  const { addNewClassifiedAd, updateClassifiedAd } = useFirestore();

  // Create new classified ad
  const newDoc = (data: Inputs): Promise<void> => {
    const progressArray: Array<number> = data.photo.map(() => 0);
    setUploadProgressArray(progressArray);
    const uploadTasks: Task.Task<string>[] = data.photo.map(
      (file, i) => () =>
        uploadPhoto(file, (progress) => {
          const newArray = uploadProgressArray;
          newArray[i] = progress;
          setUploadProgressArray([...newArray]);
        })
    );

    const uploadTask = pipe(
      uploadTasks,
      ArrayFP.sequence(Task.ApplicativeSeq),
      Task.map((urls) => {
        const newDoc: ClassifiedAdDocument = {
          id: uuidv4(),
          title: data.title,
          description: data.description,
          photoUrl: urls,
          platform: data.platform,
          weight: data.weight,
          price: data.price,
          status: ClassifiedAdStatusEnum.CREATION,
          createdDate: Timestamp.now(),
          updatedAt: Timestamp.now(),
          soldDate: null,
          receivedBy: null,
        };
        return newDoc;
      }),
      Task.chain((newDoc) => () => addNewClassifiedAd(newDoc))
    );

    return uploadTask();
  };

  // Update classified ad
  const updateDoc = ({
    description,
    platform,
    price,
    title,
    weight,
  }: Inputs): Promise<void> => {
    if (initialValue != undefined) {
      console.log("update classified ad");
      const updateObject: UpdateClassifiedAddObject = {
        title,
        description,
        price: +price,
        weight: +weight,
        platform,
        soldDate: initialValue.soldDate,
      };
      return updateClassifiedAd(initialValue.id, updateObject);
    } else {
      return Promise.reject(new Error("No initial value"));
    }
  };

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    console.log(`data submitted: ${data}`);
    setShowBackdrop(true);

    const upsertPromise = isNewClassifiedAdd ? newDoc(data) : updateDoc(data);

    upsertPromise
      .then(() => {
        navigate(-1);
      })
      .catch((err) => {
        setShowBackdrop(false);
        setErrorSnackBarMessage(
          "Impossible de sauvegarder l'annonce. Veuillez réessayer..."
        );
        console.error("Error while saving data", err);
      });
  };

  return (
    <>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "2em" }}>
            <FormControl required>
              <FormLabel> Titre </FormLabel>
              <Input {...register("title", { required: true })} />
            </FormControl>
            <Box>
              <FormLabel>Description</FormLabel>
              <Textarea minRows={4} {...register("description")} />
            </Box>
            {isNewClassifiedAdd ? (
              <Controller
                name={"photo"}
                control={control}
                defaultValue={[]}
                render={({ field: { value, onChange } }) => (
                  <ImagePicker value={value} onChange={onChange} />
                )}
              />
            ) : (
              initialValue && (
                <ImageViewerComponent imagesUrl={initialValue.photoUrl} />
              )
            )}
            <FormControl>
              <FormLabel>Prix</FormLabel>
              <Input {...register("price", { required: true, min: 1 })} />
            </FormControl>
            <Controller
              name={"weight"}
              control={control}
              defaultValue={2}
              render={({ field: { value, onChange } }) => (
                <WeightSlider value={value} onChange={onChange} />
              )}
            />
            <Controller
              name={"platform"}
              control={control}
              defaultValue={PlatformEnum.LEBONCOIN}
              render={({ field: { value, onChange } }) => (
                <PlatformSelector value={value} onChange={onChange} />
              )}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Button
                color={"neutral"}
                variant={"outlined"}
                onClick={onCancel}
                type={"button"}
              >
                Annuler
              </Button>
              <Button
                color={"primary"}
                startDecorator={<FaPaperPlane />}
                type={"submit"}
              >
                Enregistrer
              </Button>
            </Box>
          </Box>
        </form>
      </Card>
      <Backdrop open={showBackdrop}>
        <ProgressComponent progress={uploadProgressArray} />
      </Backdrop>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={errorSnackBarMessage != null}
        key={"error-snackbar"}
        autoHideDuration={5000}
      >
        <Alert color={"error"}>{errorSnackBarMessage}</Alert>
      </Snackbar>
    </>
  );
};

export default ClassifiedAdForm;
