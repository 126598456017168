// Initialize Firebase
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../firebaseConfig";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

export const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const firebaseAuth = getAuth(firebaseApp);
export const googleAuthProvider = new GoogleAuthProvider();

// Initialize Realtime Database and get a reference to the service
export const firebaseDb = getDatabase(firebaseApp);

// Initialize Cloud Storage and get a reference to the service
export const firebaseStorage = getStorage(firebaseApp);

// Initialize Cloud Firestore and get a reference to the service
export const firebaseFirestore = getFirestore(firebaseApp);

if (
  window.location.hostname === "127.0.0.1" ||
  window.location.hostname === "localhost"
) {
  connectDatabaseEmulator(firebaseDb, "localhost", 9000);
  connectStorageEmulator(firebaseStorage, "localhost", 9199);
  connectFirestoreEmulator(firebaseFirestore, "localhost", 8080);
}
