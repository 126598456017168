import { Fab, Grid } from "@mui/material";
import { Sheet, styled, Typography } from "@mui/joy";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { usePurchasedStuff } from "../hooks/usePurchasedStuff";
import { TotalPurchasedStuff } from "../components/TotalPurchasedStuff";
import { SumPurchasedStuff } from "../components/SumPurchasedStuff";
import React from "react";

export const PurchasedStuffHomePage = () => {
  const navigate = useNavigate();

  const { purchasedStuff } = usePurchasedStuff();

  const Item = styled(Sheet)(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.background.level1 : "#fff",
    ...theme.typography["body-sm"],
    padding: theme.spacing(1),
    textAlign: "center",
    borderRadius: 4,
    color: theme.vars.palette.text.secondary,
  }));

  const handleAddPurchasedStuff = () => {
    navigate("/purchasedStuff/add");
  };

  return (
    <Sheet sx={{ padding: "1em", height: "100%" }}>
      <Typography level={"h1"}>Le matos</Typography>
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid item xs={6}>
          <Item>
            <SumPurchasedStuff purchasedStuff={purchasedStuff} />
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <TotalPurchasedStuff purchasedStuff={purchasedStuff} />
          </Item>
        </Grid>
      </Grid>
      <Fab
        sx={{ position: "fixed", bottom: "3em", right: "3em" }}
        color={"primary"}
        onClick={handleAddPurchasedStuff}
      >
        <FaPlus />
      </Fab>
    </Sheet>
  );
};
