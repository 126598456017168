import {
  ref,
  get,
  child,
  set,
  update,
  onValue,
  DataSnapshot,
} from "firebase/database";
import { firebaseDb } from "../firebase/FirebaseService";
import { v4 as uuidv4 } from "uuid";
import { pipe } from "fp-ts/function";
import { Operation, User as UserDB } from "../types/Types";
import * as Option from "fp-ts/Option";
import * as Task from "fp-ts/Task";
import * as ArrayFP from "fp-ts/Array";

type ExpenseReportDBModel = {
  id: string;
  from: string;
  to: string;
  description: string;
  amount: number;
  createdAt: string;
  updatedAt: string | null;
  removedAt: string | null;
};

type PayToDBModel = {
  to: string;
  from: string;
  expenseId: string;
  payedAt: string | null;
};

export const addUserInformation = (user: UserDB): Promise<void> => {
  return set(ref(firebaseDb, "/user/" + user.id), user);
};

type OperationUsers = {
  from: UserDB;
  to: UserDB;
};
const getUsersForOperation = (
  fromId: string,
  toId: string
): Task.Task<Option.Option<OperationUsers>> => {
  return pipe(
    getSingleUser(fromId),
    Task.chain((maybeFromUser) =>
      pipe(
        maybeFromUser,
        Option.map((fromUser) =>
          pipe(
            getSingleUser(toId),
            Task.map((maybeToUser) =>
              pipe(
                maybeToUser,
                Option.map((toUser) => {
                  return { to: toUser, from: fromUser } as OperationUsers;
                })
              )
            )
          )
        ),
        Option.sequence(Task.ApplicativeSeq),
        Task.map(Option.flatten)
      )
    )
  );
};

/**
 * Get 1 operation from DB
 * @param userId
 * @param expenseReportId
 */
const getSingleOperation = (
  userId: string,
  expenseReportId: string
): Task.Task<Option.Option<Operation>> => {
  console.log("getSingleOperation");
  return pipe(
    getSingleExpenseReportDBModel(userId, expenseReportId),
    Task.chain((maybeExpenseReport) =>
      pipe(
        maybeExpenseReport,
        Option.map((expenseReport) =>
          pipe(
            getSinglePayToDBModel(expenseReportId),
            Task.chain((maybePayTo) =>
              pipe(
                maybePayTo,
                Option.map((payTo) =>
                  pipe(
                    getUsersForOperation(expenseReport.from, expenseReport.to),
                    Task.map((maybeUsers) =>
                      pipe(
                        maybeUsers,
                        Option.map((users) =>
                          extractExpenseReportDBModelToOperation(
                            expenseReport,
                            payTo,
                            users.from,
                            users.to
                          )
                        )
                      )
                    )
                  )
                ),
                Option.sequence(Task.ApplicativeSeq),
                Task.map((s) => pipe(s, Option.flatten))
              )
            )
          )
        ),
        Option.sequence(Task.ApplicativeSeq),
        Task.map((s) => pipe(s, Option.flatten))
      )
    )
  );
};

/**
 * Get expense reports created by the user
 * @param userId
 * @param onOperationUpdate
 */
const getExpenseReportsListener = (
  userId: string,
  onOperationUpdate: (reports: Promise<Operation[]>) => void
) => {
  onValue(ref(firebaseDb, "/expenseReport/" + userId), (snapshot) => {
    let result: Array<Task.Task<Option.Option<Operation>>> = [];

    snapshot.forEach((child) => {
      const id = child.key;
      if (id != null) {
        const operationDB = extractExpenseReportDBModel(id, child);
        const task: Task.Task<Option.Option<Operation>> = pipe(
          getPayTo(operationDB.id),
          Task.map((maybePayedAt) => {
            return pipe(
              maybePayedAt,
              Option.map((opt) => (opt.payedAt != null ? opt.payedAt : "")),
              Option.getOrElse(() => "")
            );
          }),
          Task.chain((payedAt) => {
            return pipe(
              getSingleUser(operationDB.from),
              Task.chain((maybeFromUser) =>
                pipe(
                  maybeFromUser,
                  Option.map((fromUser) =>
                    pipe(
                      getSingleUser(operationDB.to),
                      Task.map((maybeToUser) =>
                        pipe(
                          maybeToUser,
                          Option.map((toUser) => {
                            return {
                              ...operationDB,
                              payedAt,
                              to: toUser,
                              from: fromUser,
                            } as Operation;
                          })
                        )
                      )
                    )
                  ),
                  Option.sequence(Task.ApplicativeSeq),
                  Task.map(Option.flatten)
                )
              )
            );
          })
        );

        result = [...result, task];
      }
    });

    // Last conversion
    const resultPromise: Task.Task<Operation[]> = pipe(
      result,
      ArrayFP.sequence(Task.ApplicativeSeq),
      Task.map((array) =>
        pipe(
          array,
          ArrayFP.chain((maybeOperation) =>
            pipe(maybeOperation, ArrayFP.fromOption)
          )
        )
      ),
      Task.map((operations) => operations.filter((o) => o.removedAt == null))
    );

    onOperationUpdate(resultPromise());
  });
};

const getSinglePayToDBModel = (
  operationId: string
): Task.Task<Option.Option<PayToDBModel>> => {
  console.log("getSinglePayToDBModel");
  const refDb = ref(firebaseDb);
  const url = `/payTo/${operationId}`;

  return pipe(
    () => get(child(refDb, url)),
    Task.map((snapshot) =>
      snapshot.exists() ? Option.some(snapshot) : Option.none
    ),
    Task.map((maybeSnapshot) =>
      pipe(
        maybeSnapshot,
        Option.map((data) => payToModelReader(data))
      )
    )
  );
};

const getSingleExpenseReportDBModel = (
  userId: string,
  operationId: string
): Task.Task<Option.Option<ExpenseReportDBModel>> => {
  const refDb = ref(firebaseDb);
  const url = `/expenseReport/${userId}/${operationId}`;

  return pipe(
    () => get(child(refDb, url)),
    Task.map((snapshot) =>
      snapshot.exists() ? Option.some(snapshot) : Option.none
    ),
    Task.map((maybeSnapshot) =>
      pipe(
        maybeSnapshot,
        Option.map((data) => extractExpenseReportDBModel(operationId, data))
      )
    )
  );
};

const getExpenseToPayListener = (
  userId: string,
  onOperationUpdate: (reports: Promise<Operation[]>) => void
) => {
  console.log("getSinglePayToDBModel");
  onValue(ref(firebaseDb, "/payTo"), (snapshot) => {
    let payToDBModelList: PayToDBModel[] = [];
    snapshot.forEach((child) => {
      const payToDBModel = extractPayTo(child);
      payToDBModelList = [...payToDBModelList, payToDBModel];
    });

    const operationsTask = pipe(
      payToDBModelList,
      ArrayFP.filter((e) => e.to === userId),
      ArrayFP.map((payToModel) => {
        return pipe(
          getSingleExpenseReportDBModel(payToModel.from, payToModel.expenseId),
          Task.chain((maybeValue) =>
            pipe(
              maybeValue,
              Option.map((expenseModelDB) =>
                pipe(
                  getUsersForOperation(expenseModelDB.from, expenseModelDB.to),
                  Task.map((maybeUsers) =>
                    pipe(
                      maybeUsers,
                      Option.map((users) =>
                        extractExpenseReportDBModelToOperation(
                          expenseModelDB,
                          payToModel,
                          users.from,
                          users.to
                        )
                      )
                    )
                  )
                )
              ),
              Option.sequence(Task.ApplicativeSeq),
              Task.map(Option.flatten)
            )
          )
        );
      }),
      ArrayFP.sequence(Task.ApplicativeSeq),
      Task.map((array) =>
        pipe(
          array,
          ArrayFP.chain((elem) => pipe(elem, ArrayFP.fromOption))
        )
      ),
      Task.map((operations) => operations.filter((o) => o.removedAt == null))
    );

    onOperationUpdate(operationsTask());
  });
};

/**
 * Add new expense report in DB
 *
 * @param userId user who emitted expense report
 * @param operation the operation
 */
const addExpenseReports = (
  userId: string,
  operation: Operation
): Promise<void> => {
  console.log("addExpenseReports");
  console.log(`data : to : ${operation.to.id}`);
  // Transform operation object to ExpenseReportDBModel
  const uuid = uuidv4();
  const operationDBModel: ExpenseReportDBModel = {
    id: uuid,
    to: operation.to.id,
    from: operation.from.id,
    createdAt: operation.createdAt,
    amount: operation.amount,
    description: operation.description,
    removedAt: null,
    updatedAt: null,
  };
  console.log(`data : to : ${operationDBModel.to}`);

  return pipe(
    addExpenseReportsDB(operationDBModel),
    Task.chain(() => setPayTo(operationDBModel, null))
  )();
};

/**
 * Soft remove expense report
 * @param userId
 * @param operationId
 */
const removeExpenseReports = (
  userId: string,
  operationId: string
): Promise<void> => {
  const softRemove = (operation: ExpenseReportDBModel): Task.Task<void> => {
    if (operation != null) {
      const removedOperation = { ...operation, removedAt: Date.now() };
      return () =>
        update(
          ref(firebaseDb, `expenseReport/${userId}/${operationId}`),
          removedOperation
        );
    }
    console.error(`Unable to find operationId: ${operationId}`);
    return () => Promise.resolve();
  };

  return pipe(
    getSingleExpenseReportDBModel(userId, operationId),
    Task.chain((maybeExpenseReport) =>
      pipe(
        maybeExpenseReport,
        Option.map((expenseReport) => softRemove(expenseReport)),
        Option.sequence(Task.ApplicativeSeq)
      )
    ),
    Task.map((value) =>
      pipe(
        value,
        Option.map(() => undefined),
        Option.getOrElse(() => {
          console.error("Something went wrong when removing expense report");
          return undefined;
        })
      )
    )
  )();
};

/**
 * Update an expense report
 * @param expenseReport
 */
const updateExpenseReport = (expenseReport: Operation): Promise<void> => {
  const newExpenseReport = extractOperationToExpenseReport(expenseReport);
  return update(
    ref(
      firebaseDb,
      `expenseReport/${expenseReport.from.id}/${expenseReport.id}`
    ),
    newExpenseReport
  );
};

/**
 * Insert in DB expense report
 * @param operationDBModel
 */
const addExpenseReportsDB = (
  operationDBModel: ExpenseReportDBModel
): Task.Task<void> => {
  return () =>
    set(
      ref(
        firebaseDb,
        "/expenseReport/" + operationDBModel.from + "/" + operationDBModel.id
      ),
      operationDBModel
    );
};

/**
 * Insert in DB payTo element for an expense report
 * @param expenseId
 * @param to
 * @param from
 * @param payedAt
 */
const setPayTo = (
  { id: expenseId, to, from }: ExpenseReportDBModel,
  payedAt: string | null
): Task.Task<void> => {
  const payedToObject: PayToDBModel = { expenseId, payedAt, to, from };
  console.log(
    `setPayTo object expenseId:${[payedToObject.expenseId]} to:${[
      payedToObject.to,
    ]} from:${[payedToObject.from]}`
  );
  return () => set(ref(firebaseDb, `/payTo/${expenseId}`), payedToObject);
};

/**
 * Extract DataSnapShot into PayToDBModel
 * @param dataSnapShot
 */
const payToModelReader = (dataSnapShot: DataSnapshot): PayToDBModel => {
  const expenseId = dataSnapShot.child("expenseId").val();
  const payedAt = dataSnapShot.child("payedAt").val();
  const to = dataSnapShot.child("to").val();
  const from = dataSnapShot.child("from").val();

  const model: PayToDBModel = {
    expenseId,
    payedAt,
    to,
    from,
  };
  return model;
};

/**
 * Get PayTo from DB
 * @param expenseReportId
 */
const getPayTo = (
  expenseReportId: string
): Task.Task<Option.Option<PayToDBModel>> => {
  const refDb = ref(firebaseDb);
  return pipe(
    () => get(child(refDb, `/payTo/${expenseReportId}`)),
    Task.map((d) => {
      if (d.exists()) {
        const model = payToModelReader(d);

        return Option.some(model);
      }
      return Option.none;
    })
  );
};

/**
 * Set payed expense report
 * @param expenseReportId
 * @param date
 */
const setPayed = (
  expenseReportId: string,
  date: string
): Task.Task<Option.Option<void>> => {
  const updatePayedTo = (payToModel: PayToDBModel): Task.Task<void> => {
    if (payToModel.to.length === 0) {
      console.error(`Can't update payTo ${payToModel.expenseId}`);
      return () => Promise.resolve();
    }
    return () =>
      update(ref(firebaseDb, `payTo/${expenseReportId}`), payToModel);
  };

  return pipe(
    getPayTo(expenseReportId),
    Task.chain((maybePayTo) =>
      pipe(
        maybePayTo,
        Option.map((payToModel) => {
          const newPayToModel = { ...payToModel, payedAt: date };
          return updatePayedTo(newPayToModel);
        }),
        Option.sequence(Task.ApplicativeSeq)
      )
    )
  );
};

/**
 * Get single user
 * @param userId
 */
const getSingleUser = (userId: string): Task.Task<Option.Option<UserDB>> => {
  const refDb = ref(firebaseDb);
  return pipe(
    () => get(child(refDb, `/user/${userId}`)),
    Task.map((snapshot) =>
      snapshot.exists() ? Option.some(snapshot) : Option.none
    ),
    Task.map((maybeSnapshot) => pipe(maybeSnapshot, Option.map(extractUserDB)))
  );
};

const getUsers = (onUserUpdate: (user: UserDB[]) => void) => {
  console.log("getUsers");
  onValue(ref(firebaseDb, "/user"), (snapshot) => {
    let result: UserDB[] = [];
    snapshot.forEach((child) => {
      const id = child.key;
      if (id != null) {
        const userDB = extractUserDB(child);
        result = [...result, userDB];
      }
    });
    onUserUpdate(result);
  });
};

const extractUserDB = (data: DataSnapshot): UserDB => {
  const id = data.child("id").val();
  const email = data.child("email").val();
  const displayName = data.child("displayName").val();
  const photoURL = data.child("photoURL").val();

  return { id, email, displayName, photoURL };
};

/**
 * Extract dataSnapshot to ExpenseReportDBModel
 * @param id
 * @param snapshot
 */
const extractExpenseReportDBModel = (
  id: string,
  snapshot: DataSnapshot
): ExpenseReportDBModel => {
  const amount = Number.parseFloat(snapshot.child("amount").val());
  const description = snapshot.child("description").val();
  const createdAt = snapshot.child("createdAt").val();
  const to = snapshot.child("to").val();
  const from = snapshot.child("from").val();
  const removedAt = snapshot.child("removedAt").val();
  const updatedAt = snapshot.child("updatedAt").val();

  return {
    id,
    amount,
    to,
    description,
    createdAt,
    from,
    removedAt,
    updatedAt,
  };
};

/**
 * Extract dataSnapshot to PayToDBModel
 * @param data
 */
const extractPayTo = (data: DataSnapshot): PayToDBModel => {
  const payedAt = data.child("payedAt").val();
  const to = data.child("to").val();
  const from = data.child("from").val();
  const expenseId = data.child("expenseId").val();

  return { payedAt, to, from, expenseId };
};

/**
 * Transform Db model to produce Operation object
 * @param expenseReportsDB
 */
const extractExpenseReportDBModelToOperation = (
  expenseReportsDB: ExpenseReportDBModel,
  payToDBModel: PayToDBModel,
  from: UserDB,
  to: UserDB
): Operation => {
  const { amount, id, description, createdAt, removedAt, updatedAt } =
    expenseReportsDB;
  const payedAt = payToDBModel.payedAt != null ? payToDBModel.payedAt : "";
  const result: Operation = {
    id,
    amount,
    description,
    createdAt,
    from: from,
    to: to,
    updatedAt,
    payedAt,
    removedAt,
  };
  return result;
};

/**
 * Convert Operation object to ExpenseReportDBModel
 * @param id
 * @param amount
 * @param to
 * @param from
 * @param updatedAt
 * @param description
 * @param createdAt
 * @param removedAt
 */
const extractOperationToExpenseReport = ({
  id,
  amount,
  to: { id: toId },
  from: { id: fromId },
  updatedAt,
  description,
  createdAt,
  removedAt,
}: Operation): ExpenseReportDBModel => {
  const result: ExpenseReportDBModel = {
    id,
    amount,
    to: toId,
    from: fromId,
    updatedAt,
    description,
    createdAt,
    removedAt,
  };
  return result;
};

export const useFirebaseDB = () => {
  return {
    addExpenseReports,
    addUserInformation,
    removeExpenseReports,
    getExpenseReportsListener,
    getExpenseToPayListener,
    getUsers,
    getSingleOperation,
    getSingleUser,
    setPayed,
    updateExpenseReport,
  };
};
