import React from "react";
import { Card, Sheet, Typography } from "@mui/joy";
import { Operation } from "../../types/Types";
import { Legend, Pie, PieChart } from "recharts";
import { pipe } from "fp-ts/function";
import * as ArrayFP from "fp-ts/Array";

type DataItem = {
  name: string;
  value: number;
  fill: string;
};

type SummaryPieChartProps = {
  data: DataItem[];
};

const getDeclaredOperationSummary = (
  operations: Operation[],
  userId: string
): DataItem[] => {
  type OperationStatus = {
    payed: number[];
    unpayed: number[];
  };
  const ownOperation = pipe(
    operations,
    ArrayFP.filter((o) => o.from.id === userId),
    ArrayFP.reduce(
      { unpayed: [], payed: [] },
      (acc: OperationStatus, cur: Operation) => {
        const amount = cur.amount;
        if (cur.payedAt != "") {
          return { ...acc, payed: [...acc.payed, amount] };
        } else {
          return { ...acc, unpayed: [...acc.unpayed, amount] };
        }
      }
    )
  );

  const payedDataItem: DataItem = pipe(
    ownOperation.payed,
    ArrayFP.reduce(
      { name: "Payé", value: 0, fill: "#2ecc71" },
      (acc: DataItem, cur: number) => {
        return { ...acc, value: acc.value + cur };
      }
    )
  );

  const unpayedDataItem: DataItem = pipe(
    ownOperation.unpayed,
    ArrayFP.reduce(
      { name: "Non payé", value: 0, fill: "#e74c3c" },
      (acc: DataItem, cur: number) => {
        return { ...acc, value: acc.value + cur };
      }
    )
  );
  return [payedDataItem, unpayedDataItem];
};

const SummaryPieChart = ({ data }: SummaryPieChartProps) => {
  return (
    <PieChart width={300} height={250}>
      <Pie
        data={data}
        dataKey={"value"}
        nameKey={"name"}
        outerRadius={80}
        label
        animationDuration={1000}
      />
      <Legend height={36} />
    </PieChart>
  );
};

type SummaryProps = {
  operations: Operation[];
  userId: string;
};
export default function Summary({ operations, userId }: SummaryProps) {
  const sum = operations.reduce((a, b) => a + b.amount, 0);
  const dataOwnReport = getDeclaredOperationSummary(operations, userId);

  return (
    <Card
      sx={{ margin: "1em", display: "flex", flexDirection: "column" }}
      variant={"plain"}
    >
      <Typography textAlign="left" level={"h2"}>
        Résumé
      </Typography>
      <Sheet
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
      >
        <Sheet>
          <SummaryPieChart data={dataOwnReport} />
          <Typography textAlign="center" level={"body-md"}>
            Total: {sum.toFixed(2)} €
          </Typography>
        </Sheet>
      </Sheet>
    </Card>
  );
}
