import * as React from "react";
import { Container, Typography } from "@mui/joy";
import ClassifiedAdForm from "./form/ClassifiedAdForm";
import { useNavigate, useParams } from "react-router-dom";
import { useFirestore } from "../../../services/FirestoreService";
import { useEffect, useState } from "react";
import { ClassifiedAdDocument } from "../../../types/Types";
import { pipe } from "fp-ts/function";
import * as Task from "fp-ts/Task";
import * as Option from "fp-ts/Option";

const UpdateView = () => {
  const [ad, setAd] = useState<undefined | null | ClassifiedAdDocument>(
    undefined
  );
  const navigate = useNavigate();
  const { docId } = useParams();
  const { getClassifiedAdById } = useFirestore();

  useEffect(() => {
    if (docId != undefined) {
      pipe(
        getClassifiedAdById(docId),
        Task.map((maybeAd) => {
          if (Option.isNone(maybeAd)) {
            console.log("classified ad not found");
            setAd(null);
          } else {
            pipe(
              maybeAd,
              Option.map((ad) => setAd(ad))
            );
          }
        })
      )();
    }
  }, []);

  const onCancel = () => {
    navigate(-1);
  };
  return (
    <Container maxWidth={"md"} sx={{ paddingBottom: "5em" }}>
      <Typography level={"h1"}>Modification</Typography>
      {ad != null && ad != undefined ? (
        <ClassifiedAdForm
          isNewClassifiedAdd={false}
          onCancel={onCancel}
          initialValue={ad}
        />
      ) : ad === null ? (
        <p>Introuvable</p>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default UpdateView;
