import * as React from "react";
import { FormLabel, Slider } from "@mui/joy";

const marks = [
  {
    value: 0,
    label: "0 Kg",
  },
  {
    value: 5,
    label: "5 Kg",
  },
  {
    value: 10,
    label: "10 Kg",
  },
  {
    value: 15,
    label: "15 Kg",
  },
  {
    value: 20,
    label: "20 Kg",
  },
  {
    value: 25,
    label: "25 Kg",
  },
  {
    value: 30,
    label: "30+ Kg",
  },
];

type WeightSliderProps = {
  value: number;
  onChange: (val: number) => void;
};

export function WeightSlider({ value, onChange }: WeightSliderProps) {
  const handleChange = (val: number | number[]) => {
    if (!Array.isArray(val)) {
      onChange(val);
    }
  };

  return (
    <>
      <FormLabel>Poids</FormLabel>
      <Slider
        min={0}
        max={30}
        marks={marks}
        valueLabelDisplay="auto"
        value={value}
        onChange={(event, newVal) => handleChange(newVal)}
      />
    </>
  );
}
