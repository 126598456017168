import React, { useEffect, useState } from "react";
import { Card, Typography } from "@mui/joy";
import { getSumByUser } from "../../../services/ClassifiedAdMetricsService";
import { useFirebaseDB } from "../../../services/DatabaseService";
import { User } from "../../../types/Types";
import { SumByUserItem } from "../../../types/AdMetricsDto";
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from "recharts";

const COLORS = [
  "#2980b9",
  "#d35400",
  "#27ae60",
  "#8e44ad",
  "#2c3e50",
  "#e74c3c",
];

const getData = (
  userMap: Map<string, User>,
  sumByUserItems: SumByUserItem[]
) => {
  return sumByUserItems.map((item) => {
    const userName =
      item.user === "None" ? "Inconnu" : userMap.get(item.user)?.displayName;
    return { name: userName, value: item.amount };
  });
};

const ClassifiedAdByUserIndicatorComponent = () => {
  const [users, setUsers] = useState<Map<string, User>>(new Map());
  const [sumByUser, setSumByUser] = useState<SumByUserItem[]>([]);
  const { getUsers } = useFirebaseDB();

  const pieData = getData(users, sumByUser);

  useEffect(() => {
    getUsers((users) => {
      const userMap = new Map<string, User>();
      users.forEach((user) => userMap.set(user.id, user));
      setUsers(userMap);
    });
  }, [getUsers]);

  useEffect(() => {
    if (users !== undefined) {
      getSumByUser()
        .then((result) => setSumByUser(result))
        .catch((err) => console.error("Cannot fetch sum", err));
    }
  }, [users]);

  return (
    <Card
      sx={(theme) => ({
        backgroundColor: theme.vars.palette.background.level1,
      })}
    >
      <Typography level={"h3"}>La cagnotte</Typography>
      <ResponsiveContainer width={"100%"} maxHeight={400} aspect={16 / 9}>
        <PieChart>
          <Legend verticalAlign="bottom" height={36} />
          <Pie
            data={pieData}
            innerRadius={40}
            outerRadius={60}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
            label
          >
            {pieData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default ClassifiedAdByUserIndicatorComponent;
