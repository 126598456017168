import React from "react";
import {
  Box,
  Button,
  Dropdown,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
  Typography,
} from "@mui/joy";
import { FaUserAlt, FaSignOutAlt } from "react-icons/fa";
import { useAuth } from "../services/AuthService";
import { BrowserView, MobileView } from "react-device-detect";
import { Divider } from "@mui/material";

const AccountMenu = () => {
  const { signOut, user } = useAuth();

  return (
    <Box>
      <BrowserView>
        <Dropdown>
          <MenuButton color={"neutral"} size={"lg"} variant={"plain"}>
            <FaUserAlt />
          </MenuButton>
          <Menu id="account-menu">
            {user != null && <MenuItem disabled>{user.displayName}</MenuItem>}
            <MenuItem onClick={signOut}>
              <ListItemDecorator>
                <FaSignOutAlt />
              </ListItemDecorator>{" "}
              Se déconnecter
            </MenuItem>
          </Menu>
        </Dropdown>
      </BrowserView>
      <MobileView>
        {user != null && (
          <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography startDecorator={<FaUserAlt />}>
              {user.displayName}
            </Typography>
            <Button
              sx={{ width: "100%" }}
              variant={"outlined"}
              onClick={signOut}
              color={"danger"}
              size={"sm"}
            >
              Se déconnecter
            </Button>
            <Divider sx={{ marginTop: 1 }} />
          </Box>
        )}
      </MobileView>
    </Box>
  );
};

export default AccountMenu;
