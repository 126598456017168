import React, { useState } from "react";
import { useFirebaseDB } from "../../../services/DatabaseService";
import { formatDate } from "../../../utils/DateUtils";
import * as Option from "fp-ts/Option";
import { Button, Typography } from "@mui/joy";
import { FaRegCreditCard } from "react-icons/fa";
import ConfirmationDialog from "../../modals/ConfirmationDialog";
import { BrowserView, MobileView } from "react-device-detect";

type PayButtonProps = {
  expenseReportId: string;
};
const PayButton = ({ expenseReportId }: PayButtonProps) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const { setPayed } = useFirebaseDB();

  const handlePayButton = (): Promise<void> => {
    return setPayed(expenseReportId, formatDate(new Date()))().then((maybe) =>
      Option.isNone(maybe) ? console.error("Failed to set payed") : undefined
    );
  };

  return (
    <>
      <BrowserView>
        <Button
          color={"primary"}
          variant={"soft"}
          onClick={() => setDialogVisible(true)}
        >
          <Typography
            color={"primary"}
            startDecorator={<FaRegCreditCard size={18} />}
          >
            Payer
          </Typography>
        </Button>
      </BrowserView>
      <MobileView>
        <Button variant={"plain"} onClick={() => setDialogVisible(true)}>
          <Typography
            color={"neutral"}
            level={"body-md"}
            startDecorator={<FaRegCreditCard />}
          >
            Payer
          </Typography>
        </Button>
      </MobileView>
      <ConfirmationDialog
        visible={dialogVisible}
        setVisible={setDialogVisible}
        message={"Voulez-vous payer la note ?"}
        color={"success"}
        confirmMessage={"Payer"}
        onConfirm={handlePayButton}
      />
    </>
  );
};

export default PayButton;
