import * as React from "react";
import { useEffect, useState } from "react";
import {
  ClassifiedAdDocument,
  ClassifiedAdStatusEnum,
  PlatformEnum,
} from "../../types/Types";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Dropdown,
  ListDivider,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
  Typography,
} from "@mui/joy";
import { limit } from "../../utils/StringUtils";
import { getPlatformColor } from "../../utils/PlatformUtils";
import {
  getStatusFromId,
  getStatusLabel,
} from "../../utils/ClassifiedAdStatusUtils";
import {
  FaClipboardList,
  FaDownload,
  FaEllipsisV,
  FaFileUpload,
  FaRegArrowAltCircleRight,
  FaRegEdit,
  FaTrash,
} from "react-icons/fa";
import { UpdateStatusModal } from "./sub/UpdateStatusModal";
import { GoNextStatusModal } from "./sub/GoNextStatusModal";
import { useNavigate } from "react-router-dom";
import { UploadDeliveryVoucherModal } from "./sub/UploadDeliveryVoucherModal";
import { RemoveAdModal } from "./sub/RemoveModal";
import { useFirebaseDB } from "../../services/DatabaseService";
import { pipe } from "fp-ts/function";
import * as Task from "fp-ts/Task";
import * as Option from "fp-ts/Option";
import { formatDate } from "../../utils/DateUtils";

type Props = {
  classifiedAd: ClassifiedAdDocument;
  removeDocIdOnUpdate: (docId: string) => void;
};

type PlatformChipProps = {
  platform: PlatformEnum;
};
const PlatformChip = ({ platform }: PlatformChipProps) => {
  const colorCode = getPlatformColor(platform);
  return (
    <Chip size={"sm"} sx={{ bgcolor: colorCode }}>
      {platform}
    </Chip>
  );
};

const ClassifiedAdsComponent = ({
  classifiedAd,
  removeDocIdOnUpdate,
}: Props) => {
  const [receivedByDisplayName, setReceivedByDisplayName] = useState<string>();
  const { getSingleUser } = useFirebaseDB();

  useEffect(() => {
    if (classifiedAd.receivedBy != null) {
      pipe(
        getSingleUser(classifiedAd.receivedBy),
        Task.map((maybeUser) =>
          pipe(
            maybeUser,
            Option.map((user) => setReceivedByDisplayName(user.displayName))
          )
        )
      )();
    }
  }, [classifiedAd.receivedBy, getSingleUser]);

  return (
    <Card variant={"outlined"} sx={{ minHeight: "130px", gap: 1 }}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 2,
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              justifyContent: "space-between",
            }}
          >
            <Typography level="title-sm">
              {limit(classifiedAd.title)}
            </Typography>
            <ClassifiedAdMenu
              classifiedAd={classifiedAd}
              removeDocIdOnUpdate={removeDocIdOnUpdate}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
              gap: 2,
            }}
          >
            <Typography level={"body-lg"}>{classifiedAd.price} €</Typography>
            <Typography level={"body-sm"} color={"primary"}>
              {receivedByDisplayName}
            </Typography>
          </Box>
          <Box sx={{ marginTop: "1em" }}>
            <Typography level={"body-sm"}>
              {limit(classifiedAd.description, 130)}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            height: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <PlatformChip platform={classifiedAd.platform} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
            }}
          >
            {classifiedAd.deliveryVoucher && (
              <a
                href={classifiedAd.deliveryVoucher}
                target="_blank"
                rel="noreferrer"
              >
                <Chip
                  variant={"soft"}
                  size={"sm"}
                  startDecorator={<FaDownload />}
                >
                  Bon d'envoi
                </Chip>
              </a>
            )}
          </Box>
          <Box>
            <Typography level="body-sm" textAlign={"center"}>
              {getStatusLabel(classifiedAd.status)}
            </Typography>
            {classifiedAd.soldDate !== null && (
              <Typography level="body-md" variant={"solid"}>
                {formatDate(classifiedAd.soldDate.toDate())}
              </Typography>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

type ClassifiedAdMenuProps = {
  classifiedAd: ClassifiedAdDocument;
  removeDocIdOnUpdate: (docId: string) => void;
};
const ClassifiedAdMenu = ({
  classifiedAd,
  removeDocIdOnUpdate,
}: ClassifiedAdMenuProps) => {
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);
  const [showGoNextStatusModal, setShowGoNextStatusModal] = useState(false);
  const [showUploadDeliveryVoucherModal, setShowUploadDeliveryVoucherModal] =
    useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const navigate = useNavigate();
  const handleUpdateClassifiedAd = () => navigate(`update/${classifiedAd.id}`);
  const handleRemoveClassifiedAd = () => setShowDeleteConfirmationModal(true);
  const handleShowUpdateStatusModal = () => setShowUpdateStatusModal(true);
  const handleShowGoNextStatusModal = () => setShowGoNextStatusModal(true);
  const handleShowUploadDeliveryVoucher = () =>
    setShowUploadDeliveryVoucherModal(true);
  const getNextStatus = (): null | number => {
    if (classifiedAd.status === ClassifiedAdStatusEnum.VENDU) {
      return null;
    } else {
      return classifiedAd.status + 1;
    }
  };

  const nextStatus = getNextStatus();
  const isReadyToDeliver =
    classifiedAd.status === ClassifiedAdStatusEnum.EN_LIGNE;

  return (
    <>
      <Dropdown>
        <MenuButton variant={"plain"}>
          <FaEllipsisV />
        </MenuButton>
        <Menu id="classified-ad-menu">
          <MenuItem onClick={handleUpdateClassifiedAd}>
            <ListItemDecorator>
              <FaRegEdit />
            </ListItemDecorator>
            Modifier
          </MenuItem>
          {nextStatus != null && (
            <>
              <MenuItem onClick={handleShowGoNextStatusModal}>
                <ListItemDecorator>
                  <FaRegArrowAltCircleRight />
                </ListItemDecorator>
                Passer à {getStatusLabel(getStatusFromId(nextStatus))}
              </MenuItem>
            </>
          )}
          <MenuItem onClick={handleShowUpdateStatusModal}>
            <ListItemDecorator>
              <FaClipboardList />
            </ListItemDecorator>
            Modifier le statut
          </MenuItem>
          {isReadyToDeliver && (
            <>
              <ListDivider />
              <MenuItem onClick={handleShowUploadDeliveryVoucher}>
                <ListItemDecorator>
                  <FaFileUpload />
                </ListItemDecorator>
                Charger le bon d'envoi
              </MenuItem>
            </>
          )}
          <MenuItem color={"danger"} onClick={handleRemoveClassifiedAd}>
            <ListItemDecorator sx={{ color: "inherit" }}>
              <FaTrash />
            </ListItemDecorator>
            Supprimer
          </MenuItem>
        </Menu>
      </Dropdown>
      <UpdateStatusModal
        actualStatus={classifiedAd.status}
        visible={showUpdateStatusModal}
        removeDocIdOnUpdate={removeDocIdOnUpdate}
        setVisible={setShowUpdateStatusModal}
        docId={classifiedAd.id}
      />
      <RemoveAdModal
        visible={showDeleteConfirmationModal}
        removeDocIdOnUpdate={removeDocIdOnUpdate}
        setVisible={setShowDeleteConfirmationModal}
        docId={classifiedAd.id}
      />
      {nextStatus != null && (
        <GoNextStatusModal
          nextStatus={getStatusFromId(nextStatus)}
          visible={showGoNextStatusModal}
          removeDocIdOnUpdate={removeDocIdOnUpdate}
          setVisible={setShowGoNextStatusModal}
          docId={classifiedAd.id}
        />
      )}
      {isReadyToDeliver && (
        <UploadDeliveryVoucherModal
          visible={showUploadDeliveryVoucherModal}
          removeDocIdOnUpdate={removeDocIdOnUpdate}
          setVisible={setShowUploadDeliveryVoucherModal}
          docId={classifiedAd.id}
        />
      )}
    </>
  );
};

export default ClassifiedAdsComponent;
