import React from "react";
import { CircularProgress, Typography } from "@mui/joy";

type LoaderProps = {
  text: string | null;
};
const Loader = ({ text }: LoaderProps) => {
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        level={"title-sm"}
        startDecorator={<CircularProgress color="primary" size="md" />}
      >
        {text}
      </Typography>
    </div>
  );
};

export default Loader;
