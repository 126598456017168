/**
 * Chunk string,
 * @param str
 * @param limit optional, default limit is 30 characters
 */
export const limit = (str: string, limit?: number): string => {
  const trueLimit = limit != null ? limit : 30;

  if (str.length > trueLimit) {
    return str.slice(0, trueLimit) + "...";
  } else {
    return str;
  }
};
