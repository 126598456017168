import * as React from "react";
import { Box, FormLabel, Radio, RadioGroup, Sheet } from "@mui/joy";
import { platforms } from "../../../../types/Constants";

type PlatformSelectorProps = {
  value: string;
  onChange: (val: string) => void;
};

export function PlatformSelector({ value, onChange }: PlatformSelectorProps) {
  const handleChange = (val: string) => {
    onChange(val);
  };

  return (
    <Box>
      <FormLabel id={"platform-label"}>Platforme</FormLabel>
      <RadioGroup
        name={"ad-platform"}
        value={value}
        onChange={(event) => handleChange(event.target.value)}
        overlay
        size={"lg"}
        sx={{
          flexFlow: "row wrap",
          alignItems: "center",
          justifyContent: "start",
          gap: "1em",
        }}
        defaultValue={platforms[0].value}
      >
        {platforms.map((p) => (
          <Sheet
            key={p.value}
            sx={{
              p: 2,
              borderRadius: "md",
              alignItems: "center",
              justifyContent: "center",
            }}
            variant={"outlined"}
          >
            <Radio id={p.value} value={p.value} label={p.label} />
          </Sheet>
        ))}
      </RadioGroup>
    </Box>
  );
}
