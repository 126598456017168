import { PurchasedStuff } from "../application/PurchasedStuff";
import { PurchasedStuffCard } from "./PurchasedStuffCard";

type Props = {
  purchasedStuff: PurchasedStuff[] | undefined;
};

export const SumPurchasedStuff = ({ purchasedStuff }: Props) => {
  const formatter = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 0,
  });

  const formattedValue = purchasedStuff
    ? formatter.format(
        purchasedStuff.map((stuff) => stuff.price).reduce((a, b) => a + b, 0)
      )
    : "-";

  return (
    <PurchasedStuffCard title={"Le matos"} formattedValue={formattedValue} />
  );
};
