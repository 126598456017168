import React from "react";
import { Box } from "@mui/joy";
import { GenericCard } from "./GenericCard";

type Props = {
  isLoading?: boolean;
  total?: number;
  countSold?: number;
  onSaleItems?: number;
};
export const GenericCardView = ({
  isLoading = false,
  countSold,
  onSaleItems,
  total,
}: Props) => {
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "2em" }}>
        <GenericCard
          name={"La cagnotte"}
          value={total}
          suffix={"€"}
          isLoading={isLoading}
          animated
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "2em" }}>
        <GenericCard
          name={"Articles vendus"}
          value={countSold}
          link={"/classifiedAds/5"}
          isLoading={isLoading}
        />
        <GenericCard
          name={"Annonces en cours"}
          value={onSaleItems}
          link={"/classifiedAds/2"}
          isLoading={isLoading}
        />
      </Box>
    </>
  );
};
