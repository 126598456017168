import React, { useState } from "react";
import { Operation } from "../../../types/Types";
import { useNavigate } from "react-router-dom";
import { useFirebaseDB } from "../../../services/DatabaseService";
import {
  Button,
  ListDivider,
  ListItemDecorator,
  Menu,
  MenuItem,
  Typography,
} from "@mui/joy";
import { FaAngleDown, FaPen, FaTrash } from "react-icons/fa";
import { isPayed } from "../../../utils/OperationUtils";
import ConfirmationDialog from "../../modals/ConfirmationDialog";
import { BrowserView, MobileView } from "react-device-detect";

type ActionMenuComponentProps = {
  operation: Operation;
};

const ActionMenu = ({ operation }: ActionMenuComponentProps) => {
  const [anchor, setAnchor] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const navigate = useNavigate();
  const { removeExpenseReports } = useFirebaseDB();
  const open = Boolean(anchor);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event: { currentTarget: any }) => {
    setAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setAnchor(null);
  };

  const handleRemoveButton = () => {
    setDeleteModalVisible(true);
  };

  const handleEditButton = (expenseReportId: string) =>
    navigate(`/expenseReport/update/${expenseReportId}`);

  const removeOperation = (): Promise<void> => {
    return removeExpenseReports(operation.from.id, operation.id);
  };

  return (
    <>
      <BrowserView>
        <Button variant={"soft"} color={"primary"} onClick={handleClick}>
          <Typography color={"primary"} endDecorator={<FaAngleDown />}>
            Modifier
          </Typography>
        </Button>
      </BrowserView>
      <MobileView>
        <Button
          size={"sm"}
          variant={"outlined"}
          color={"primary"}
          onClick={handleClick}
        >
          <Typography color={"primary"} level={"body-sm"}>
            Modifier
          </Typography>
        </Button>
      </MobileView>
      <Menu anchorEl={anchor} open={open} onClose={handleClose}>
        <MenuItem
          disabled={isPayed(operation)}
          onClick={() => handleEditButton(operation.id)}
        >
          <ListItemDecorator>
            <FaPen />
          </ListItemDecorator>{" "}
          Éditer
        </MenuItem>
        <ListDivider />
        <MenuItem
          variant={"soft"}
          color={"danger"}
          onClick={handleRemoveButton}
        >
          <ListItemDecorator sx={{ color: "inherit" }}>
            <FaTrash />
          </ListItemDecorator>{" "}
          Supprimer
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        visible={deleteModalVisible}
        setVisible={setDeleteModalVisible}
        confirmMessage={"Supprimer"}
        message={"Voulez-vous supprimer cette note de frais ?"}
        onConfirm={removeOperation}
      />
    </>
  );
};

export default ActionMenu;
