import React from "react";
import { useNavigate, useRouteError } from "react-router-dom";
import { Link, Typography } from "@mui/joy";

export default function ErrorPage() {
  const error = useRouteError();
  const navigate = useNavigate();

  const handleLink = () => {
    navigate("/expenseReport");
  };

  console.log(error);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "2em",
      }}
    >
      <Typography level={"h1"}>Oops cette page n'existe pas</Typography>
      <Link onClick={handleLink}>Retour à l'accueil</Link>
    </div>
  );
}
