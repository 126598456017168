import { Card, Typography } from "@mui/joy";

type Props = {
  title: string;
  formattedValue: string;
};
export const PurchasedStuffCard = ({ title, formattedValue }: Props) => {
  return (
    <Card>
      <Typography level={"title-lg"}>{title}</Typography>
      <Typography level={"body-lg"}>{formattedValue}</Typography>
    </Card>
  );
};
