import React from "react";
import ReactDOM from "react-dom/client";
import "@fontsource/inter";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./routes/errorPage";
import LoginPage from "./routes/loginPage/loginPage";
import { AuthProvider } from "./services/AuthService";
import { AuthRequired } from "./services/Context/AuthRequired";
import SetReportPage from "./routes/expenseReport/addPage/SetReportPage";
import UpdateReportPage from "./routes/expenseReport/updatePage/UpdateReportPage";
import { colors, extendTheme } from "@mui/joy";
import ClassifiedAdsHomePage from "./routes/classifiedAds/ClassifiedAdsHomePage";
import ExpenseReportHomePage from "./routes/expenseReport/HomePage";
import HomePage from "./routes/homePage/HomePage";
import NavigationBar from "./components/navigation/NavigationBar";
import ClassifiedAdAddView from "./routes/classifiedAds/sub/AddView";
import ClassifiedUpdateView from "./routes/classifiedAds/sub/UpdateView";
import { ClassifiedAdView } from "./routes/classifiedAds/ClassifiedAdView";
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles";
import { PurchasedStuffHomePage } from "./routes/purchasedStuff/views/PurchasedStuffHomePage";
import { AddPurchasedStuff } from "./routes/purchasedStuff/views/AddPurchasedStuff";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthRequired>
        <NavigationBar />
      </AuthRequired>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: (
          <AuthRequired>
            <HomePage />
          </AuthRequired>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: "classifiedAds/",
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            path: ":statusId",
            element: (
              <AuthRequired>
                <ClassifiedAdsHomePage />
              </AuthRequired>
            ),
          },
          {
            path: "add",
            element: (
              <AuthRequired>
                <ClassifiedAdAddView />
              </AuthRequired>
            ),
          },
          {
            path: "update/:docId",
            element: (
              <AuthRequired>
                <ClassifiedUpdateView />
              </AuthRequired>
            ),
          },
          {
            path: "view/:docId",
            element: (
              <AuthRequired>
                <ClassifiedAdView />
              </AuthRequired>
            ),
          },
        ],
      },
      {
        path: "expenseReport",
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: (
              <AuthRequired>
                <ExpenseReportHomePage />
              </AuthRequired>
            ),
          },
          {
            path: "add",
            element: (
              <AuthRequired>
                <SetReportPage />
              </AuthRequired>
            ),
          },
          {
            path: "update/:expenseReportId",
            element: (
              <AuthRequired>
                <UpdateReportPage />
              </AuthRequired>
            ),
          },
        ],
      },
      {
        path: "purchasedStuff",
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: (
              <AuthRequired>
                <PurchasedStuffHomePage />
              </AuthRequired>
            ),
          },
          {
            path: "add",
            element: (
              <AuthRequired>
                <AddPurchasedStuff />
              </AuthRequired>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "login",
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },
]);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const muiTheme = extendTheme({
  // This is required to point to `var(--joy-*)` because we are using `CssVarsProvider` from Joy UI.
  cssVarPrefix: "joy",
  colorSchemes: {
    light: {
      palette: {
        primary: {
          mainChannel: "#E91E63",
        },
        success: {
          mainChannel: colors.green[500],
        },
        warning: {
          mainChannel: colors.yellow[200],
        },
        common: {
          white: "#FFF",
          black: "#09090D",
        },
        divider: colors.grey[200],
        text: {
          primary: colors.grey[800],
          secondary: colors.grey[600],
        },
      },
    },
    dark: {
      palette: {
        primary: {
          mainChannel: colors.blue[600],
        },
        success: {
          mainChannel: colors.green[600],
        },
        warning: {
          mainChannel: colors.yellow[300],
        },
        common: {
          white: "#FFF",
          black: "#09090D",
        },
        divider: colors.grey[800],
        text: {
          primary: colors.grey[100],
          secondary: colors.grey[300],
        },
      },
    },
  },
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const joyTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        background: {},
        primary: {
          plainColor: "#E91E63",
          plainActiveBg: "#E91E63",
          solidBg: "#E91E63",
          solidHoverBg: "#880E4F",
          solidActiveBg: "#E91E63",
          solidDisabledBg: "#F48FB1",
          outlinedBorder: "#E91E63",
          outlinedColor: "#E91E63",
        },
      },
    },
  },
});

// You can use your own `deepmerge` function.
const materialTheme = materialExtendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: "#E91E63",
        },
      },
    },
    dark: {
      palette: {
        primary: {
          main: "#E91E63",
        },
      },
    },
  },
});

root.render(
  <React.StrictMode>
    <AuthProvider>
      <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
        <JoyCssVarsProvider defaultMode="system">
          <RouterProvider router={router} />
        </JoyCssVarsProvider>
      </MaterialCssVarsProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
