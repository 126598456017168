import { collection, getDocs } from "firebase/firestore";
import { firebaseFirestore } from "../../../firebase/FirebaseService";
import { PurchasedStuff } from "../application/PurchasedStuff";
import { useState } from "react";

const path = "purchasedStuff";
export const usePurchasedStuff = () => {
  const [purchasedStuff, setPurchasedStuff] = useState<
    PurchasedStuff[] | undefined
  >(undefined);

  const getPurchasedStuff = async () => {
    const querySnapshot = await getDocs(collection(firebaseFirestore, path));
    return querySnapshot.docs.map((querySnapshot) => {
      const partialData = querySnapshot.data();
      const id = querySnapshot.id;
      return {
        id,
        ...partialData,
      } as unknown as PurchasedStuff;
    });
  };
  getPurchasedStuff().then((data) => setPurchasedStuff(data));

  return {
    purchasedStuff,
  };
};
