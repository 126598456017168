import React, { useEffect, useState } from "react";
import { Option, Select } from "@mui/joy";
import { useFirebaseDB } from "../../services/DatabaseService";
import { User } from "../../types/Types";

type SelectUserComponentProps = {
  placeholder: string;
  value: string | null;
  onChange: (newVal: string | null) => void;
};
const SelectUserComponent = ({
  value,
  onChange,
  placeholder,
}: SelectUserComponentProps) => {
  const { getUsers } = useFirebaseDB();
  const [users, setUsers] = useState<User[]>();

  useEffect(() => {
    getUsers((user) => setUsers(user));
  }, []);

  return (
    <Select
      placeholder={placeholder}
      value={value}
      onChange={(_event, newValue) => {
        onChange(newValue);
      }}
    >
      {users?.map((user) => (
        <Option key={user.id} value={user.id}>
          {user.displayName}
        </Option>
      ))}
    </Select>
  );
};

export default SelectUserComponent;
