import { useEffect, useState } from "react";
import { pipe } from "fp-ts/function";
import * as Option from "fp-ts/Option";

type HomePageMetrics = {
  totalSold: number;
  countSold: number;
  onSaleItems: number;
  purchasedStuffCount: number;
  purchasedStuffTotal: number;
};
export const useHomePageMetrics = () => {
  const [loading, setLoading] = useState(true);
  const [homePageMetrics, setHomePageMetrics] = useState<
    HomePageMetrics | undefined
  >(undefined);

  useEffect(() => {
    setLoading(true);
    fetch(
      "https://europe-west1-papythune.cloudfunctions.net/homePageMetrics/homePageMetrics"
    )
      .then((response) => {
        if (!response.ok) {
          console.log(response.statusText);
          throw new Error(response.statusText);
        }

        return response.json() as Promise<HomePageMetrics>;
      })
      .then((metrics) => {
        setHomePageMetrics(metrics);
      })
      .finally(() => setLoading(false));
  }, []);

  return { data: pipe(homePageMetrics, Option.fromNullable), loading };
};
