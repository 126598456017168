import React from "react";
import { Operation, UserType } from "../../types/Types";
import { Box, Typography } from "@mui/joy";
import ExpenseReportsList from "./subComponents/ExpenseReportsList";
import { Grid, Skeleton } from "@mui/material";

const SkeletonList = () => {
  return (
    <Grid container columnSpacing={5} rowSpacing={1} justifyContent={"center"}>
      <Grid item xs={1}>
        <Skeleton variant={"circular"} width={30} height={30} />
      </Grid>
      <Grid item xs={3}>
        <Skeleton variant={"rounded"} />
      </Grid>
      <Grid item xs={4}>
        <Skeleton variant={"rounded"} />
      </Grid>
      <Grid item xs={4}>
        <Skeleton variant={"rounded"} />
      </Grid>
      {/* 2nd rox */}
      <Grid item xs={1}>
        <Skeleton variant={"circular"} width={30} height={30} />
      </Grid>
      <Grid item xs={3}>
        <Skeleton variant={"rounded"} />
      </Grid>
      <Grid item xs={4}>
        <Skeleton variant={"rounded"} />
      </Grid>
      <Grid item xs={4}>
        <Skeleton variant={"rounded"} />
      </Grid>
    </Grid>
  );
};

type ExpenseReportsDisplayComponentProps = {
  title: string;
  operations: Operation[] | undefined;
  userToDisplay: UserType;
};
const ExpenseReportDisplayComponent = ({
  operations,
  title,
  userToDisplay,
}: ExpenseReportsDisplayComponentProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1em",
        width: "100%",
      }}
    >
      {operations !== undefined ? (
        <>
          {operations.length > 0 && (
            <Typography level={"h3"}>{title}</Typography>
          )}
          <ExpenseReportsList
            operations={operations}
            userToDisplay={userToDisplay}
          />{" "}
        </>
      ) : (
        <SkeletonList />
      )}
    </Box>
  );
};

export default ExpenseReportDisplayComponent;
