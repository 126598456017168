import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  deleteDoc,
  updateDoc,
  where,
  orderBy,
} from "firebase/firestore";
import {
  ClassifiedAdDocument,
  ClassifiedAdStatusEnum,
  UpdateClassifiedAdObject,
} from "../types/Types";
import { firebaseFirestore } from "../firebase/FirebaseService";
import { pipe } from "fp-ts/function";
import * as Task from "fp-ts/Task";
import * as Option from "fp-ts/Option";

import { Timestamp } from "firebase/firestore";

const classifiedAdsPath = "classifiedAd";

/**
 * Add new document
 * @param ad
 */
const addNewClassifiedAd = (ad: ClassifiedAdDocument): Promise<void> => {
  return setDoc(doc(firebaseFirestore, classifiedAdsPath, ad.id), {
    id: ad.id,
    title: ad.title,
    description: ad.description,
    price: +ad.price,
    photoUrl: ad.photoUrl,
    weight: ad.weight,
    platform: ad.platform,
    status: +ad.status.valueOf(),
    createdDate: ad.createdDate,
    soldDate: ad.soldDate,
  });
};

/**
 * Update a classified ad
 * @param docId
 * @param updateValue
 */
const updateClassifiedAd = (
  docId: string,
  updateValue: UpdateClassifiedAdObject
): Promise<void> => {
  return updateDoc(
    doc(firebaseFirestore, classifiedAdsPath, docId),
    updateValue
  );
};

/**
 * Find ClassifiedAdDocument by status
 * @param status
 */
const getClassifiedAdByStatus = (
  status: ClassifiedAdStatusEnum
): Promise<ClassifiedAdDocument[]> => {
  const orderByConstraintField =
    status.valueOf() === ClassifiedAdStatusEnum.VENDU.valueOf()
      ? "soldDate"
      : "createdDate";

  const q = query(
    collection(firebaseFirestore, classifiedAdsPath),
    where("status", "==", status.valueOf()),
    orderBy(orderByConstraintField, "desc")
  );
  return pipe(
    () => getDocs(q),
    Task.map((docData) =>
      docData.docs.map((e) => e.data() as ClassifiedAdDocument)
    )
  )();
};

/**
 * Find ClassifiedAd which doesn't match with status
 * @param status
 */
const getClassifiedAdNotByStatus = (
  status: ClassifiedAdStatusEnum
): Promise<ClassifiedAdDocument[]> => {
  const q = query(
    collection(firebaseFirestore, classifiedAdsPath),
    where("status", "!=", status.valueOf())
  );
  return pipe(
    () => getDocs(q),
    Task.map((docData) =>
      docData.docs.map((e) => e.data() as ClassifiedAdDocument)
    )
  )();
};

/**
 * Get classifiedAd from id
 * @param docId
 */
const getClassifiedAdById = (
  docId: string
): Task.Task<Option.Option<ClassifiedAdDocument>> => {
  const docRef = doc(firebaseFirestore, classifiedAdsPath, docId);
  const docTask = () => getDoc(docRef);
  return pipe(
    docTask,
    Task.map((snap) =>
      snap.exists()
        ? Option.some(snap.data() as ClassifiedAdDocument)
        : Option.none
    )
  );
};

/**
 * Update a status
 * @param docId
 * @param status
 * @param receivedBy
 */
const updateStatus = (
  docId: string,
  status: ClassifiedAdStatusEnum,
  receivedBy?: string
): Promise<void> => {
  const statusValue: number = +status.valueOf();
  const soldDate: Timestamp | null =
    statusValue === ClassifiedAdStatusEnum.VENDU.valueOf()
      ? Timestamp.fromDate(new Date())
      : null;

  const baseData = {
    status: +statusValue,
    soldDate: soldDate,
  };

  const receivedByData = { ...baseData, receivedBy };

  return updateDoc(
    doc(firebaseFirestore, classifiedAdsPath, docId),
    receivedBy != undefined ? receivedByData : baseData
  );
};

/**
 * Count sold ad
 */
const countAmountSold = (): Promise<number[]> => {
  const getData = () => getClassifiedAdByStatus(ClassifiedAdStatusEnum.VENDU);
  return pipe(
    getData,
    Task.map((result) => result.map((ad) => ad.price))
  )();
};

const countNonSoldAd = (): Promise<number> => {
  const getData = () =>
    getClassifiedAdNotByStatus(ClassifiedAdStatusEnum.VENDU);
  return pipe(
    getData,
    Task.map((result) => result.length)
  )();
};

const removeClassifiedAd = (docId: string): Promise<void> => {
  return deleteDoc(doc(firebaseFirestore, classifiedAdsPath, docId));
};

export const useFirestore = () => {
  return {
    addNewClassifiedAd,
    removeClassifiedAd,
    updateClassifiedAd,
    getClassifiedAdByStatus,
    updateStatus,
    getClassifiedAdById,
    countAmountSold,
    countNonSoldAd,
  };
};
