import { PlatformEnum } from "../types/Types";

export const getPlatformColor = (platform: PlatformEnum) => {
  switch (platform) {
    case PlatformEnum.EBAY:
      return "#F5AF02";
    case PlatformEnum.LEBONCOIN:
      return "#F76A16";
    default:
      return "#484848";
  }
};
