import React, { useEffect, useState } from "react";
import { Operation, UserType } from "../../types/Types";
import { Container, Typography } from "@mui/joy";
import { useAuth } from "../../services/AuthService";
import { useFirebaseDB } from "../../services/DatabaseService";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import Summary from "../../components/summary/Summary";
import Loader from "../../components/utils/Loader";
import { Fab } from "@mui/material";
import ExpenseReportDisplayComponent from "../../components/expenseReports/ExpenseReportDisplayComponent";

export default function HomePage() {
  const [declaredOperation, setDeclaredOperation] = useState<Operation[]>();
  const [haveToPayOperation, setHaveToPayOperation] = useState<Operation[]>();
  const { user } = useAuth();
  const { getExpenseReportsListener, getExpenseToPayListener } =
    useFirebaseDB();
  const navigate = useNavigate();

  useEffect(() => {
    if (user != null) {
      getExpenseReportsListener(user.id, (p) => p.then(setDeclaredOperation));
      getExpenseToPayListener(user.id, (p) =>
        p.then((values) => {
          setHaveToPayOperation(values);
        })
      );
    }
  }, [user, getExpenseReportsListener, getExpenseToPayListener]);

  const addButtonHandler = () => {
    navigate("/expenseReport/add");
  };

  return (
    <>
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "3em",
          paddingBottom: "10em",
        }}
      >
        <Typography level={"h1"}>Notes de frais</Typography>
        {user != null && declaredOperation !== undefined ? (
          declaredOperation.length > 0 ? (
            <Summary operations={declaredOperation} userId={user.id} />
          ) : (
            <Typography textAlign={"center"} level={"body-lg"}>
              Vous n'avez aucune note de frais
            </Typography>
          )
        ) : (
          <Loader text={"Chargement"} />
        )}
        <ExpenseReportDisplayComponent
          operations={haveToPayOperation}
          title={"Mes notes à payer"}
          userToDisplay={UserType.emitter}
        />
        <ExpenseReportDisplayComponent
          operations={declaredOperation}
          title={"Mes notes de frais"}
          userToDisplay={UserType.recipient}
        />

        {(declaredOperation?.length === 0 &&
          haveToPayOperation?.length === 0) ?? (
          <Typography level={"body-lg"} textAlign={"center"}>
            Aucune note de frais
          </Typography>
        )}
      </Container>
      <Fab
        sx={{ position: "fixed", bottom: "3em", right: "3em" }}
        color={"primary"}
        onClick={addButtonHandler}
      >
        <FaPlus />
      </Fab>
    </>
  );
}
