import * as React from "react";
import { useEffect, useState } from "react";
import { Box, Container, Divider, Typography } from "@mui/joy";
import { Fab, Skeleton } from "@mui/material";
import { FaGlasses, FaPlus } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useFirestore } from "../../services/FirestoreService";
import {
  ClassifiedAdDocument,
  ClassifiedAdStatusEnum,
} from "../../types/Types";
import ClassifiedAdsComponent from "../../components/classifiedAds/ClassifiedAdsComponent";
import { ListStatusComponent } from "./sub/ListStatusComponent";
import {
  getStatusFromId,
  getStatusLabel,
} from "../../utils/ClassifiedAdStatusUtils";
import Delayed from "../../components/utils/Delayed";

const AdDisplayResponse = (props: {
  classifiedAdDocuments: ClassifiedAdDocument[];
  status: ClassifiedAdStatusEnum;
  setAds: (ads: ClassifiedAdDocument[]) => void;
}) => {
  const removeAdOnUpdate = (docId: string) => {
    const newAdsList = props.classifiedAdDocuments.filter(
      (ad) => ad.id != docId
    );
    props.setAds(newAdsList);
  };

  return (
    <>
      {props.classifiedAdDocuments.length > 0 ? (
        <>
          <Typography level={"body-sm"}>
            {props.classifiedAdDocuments.length} annonce
            {props.classifiedAdDocuments.length > 1 && "s"}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              marginBottom: "8em",
            }}
          >
            {props.classifiedAdDocuments.map((ad) => (
              <ClassifiedAdsComponent
                removeDocIdOnUpdate={removeAdOnUpdate}
                classifiedAd={ad}
                key={ad.id}
              />
            ))}
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "3em",
          }}
        >
          <FaGlasses />
          <Typography level={"body-lg"}>Aucune annonce</Typography>
          <Typography level={"body-sm"}>
            {getStatusLabel(props.status)}
          </Typography>
        </Box>
      )}
    </>
  );
};

const ClassifiedAdsHomePage = () => {
  const [ads, setAds] = useState<ClassifiedAdDocument[] | undefined>([]);
  const { statusId } = useParams();
  const navigate = useNavigate();
  const { getClassifiedAdByStatus } = useFirestore();

  const adStatus =
    statusId != null && !isNaN(parseInt(statusId))
      ? getStatusFromId(parseInt(statusId))
      : ClassifiedAdStatusEnum.CREATION;

  useEffect(() => {
    setAds(undefined);
    getClassifiedAdByStatus(adStatus)
      .then((result) => setAds(result))
      .catch((err) => console.error("Cannot fetch classified ads", err));
  }, [adStatus]);

  const addButtonHandler = () => {
    navigate("add");
  };

  const handleStatusChange = (status: ClassifiedAdStatusEnum) => {
    console.log("change status");
    navigate(`${status.valueOf()}`);
  };

  return (
    <Container
      maxWidth={"md"}
      sx={(theme) => ({
        backgroundColor: theme.vars.palette.background.level1,
      })}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          gap: "1em",
          backgroundColor: theme.vars.palette.background.level1,
        })}
      >
        <Typography level={"h3"}>Les annonces</Typography>
        <ListStatusComponent
          selectedStatus={adStatus}
          onStatusChange={handleStatusChange}
        />
        <Divider
          sx={{
            boxShadow: "0 0 0 100vmax var(--Divider-lineColor)",
            clipPath: "inset(0px -100vmax)",
          }}
        />
        {ads != undefined ? (
          <AdDisplayResponse
            classifiedAdDocuments={ads}
            setAds={setAds}
            status={adStatus}
          />
        ) : (
          <Delayed>
            <>
              <Skeleton variant={"text"} width={"100%"} height={"1em"} />
              <Box sx={{ display: "flex", flexDirection: "row", gap: "1em" }}>
                <Skeleton
                  sx={{ flex: 2 }}
                  variant={"rounded"}
                  width={"100%"}
                  height={"5em"}
                />
                <Box
                  sx={{
                    flex: 8,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: "0.5em",
                  }}
                >
                  <Skeleton variant={"text"} width={"100%"} height={"1em"} />
                  <Skeleton variant={"text"} width={"100%"} height={"1em"} />
                  <Skeleton variant={"text"} width={"100%"} height={"1em"} />
                </Box>
              </Box>
            </>
          </Delayed>
        )}
      </Box>
      <Fab
        sx={{ position: "fixed", bottom: "3em", right: "3em" }}
        color={"primary"}
        onClick={addButtonHandler}
      >
        <FaPlus />
      </Fab>
    </Container>
  );
};

export default ClassifiedAdsHomePage;
