import {
  Button,
  Card,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Sheet,
  Typography,
} from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { FaFileCirclePlus } from "react-icons/fa6";
import { useAddPurchasedStuff } from "../hooks/useAddPurchasedStuff";
import { useForm } from "react-hook-form";
import { PurchasedStuff } from "../application/PurchasedStuff";

type FormData = {
  stuff_name: string;
  stuff_amount: number;
};
export const AddPurchasedStuff = () => {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm<FormData>();
  const { addPurchasedStuff } = useAddPurchasedStuff();

  const onSubmit = handleSubmit((data) => {
    console.log(data);
    const purchasedStuff: Omit<PurchasedStuff, "updatedAt" | "id"> = {
      name: data.stuff_name,
      price: +data.stuff_amount,
      createdAt: new Date().toDateString(),
    };

    addPurchasedStuff(purchasedStuff).then(() => {
      navigate(-1);
    });
  });

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <Sheet sx={{ padding: "1em", height: "100vh" }}>
      <Card variant="outlined">
        <Typography level={"title-lg"} startDecorator={<FaFileCirclePlus />}>
          Ajouter du matos
        </Typography>
        <Divider inset={"none"} />
        <FormControl>
          <FormLabel>Nom</FormLabel>
          <Input
            placeholder="Clé à choc..."
            {...register("stuff_name", { required: true })}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Prix</FormLabel>
          <Input
            startDecorator={"€"}
            type={"number"}
            placeholder="Montant"
            {...register("stuff_amount", {
              required: true,
              min: 0,
            })}
          />
        </FormControl>
        <Button variant="solid" type={"submit"} onClick={onSubmit}>
          Ajouter
        </Button>
        <Button variant="plain" onClick={() => handleCancel()}>
          Annuler
        </Button>
      </Card>
    </Sheet>
  );
};
