import React, { useEffect, useState } from "react";
import { useFirebaseDB } from "../../../services/DatabaseService";
import { ExpenseReportFormValue, Operation, User } from "../../../types/Types";
import { useAuth } from "../../../services/AuthService";
import { formatDate } from "../../../utils/DateUtils";
import { useNavigate } from "react-router-dom";
import FormSetReportComponent from "../../../components/forms/FormSetReportComponent";
import Loader from "../../../components/utils/Loader";

const SetReportPage = () => {
  const [user, setUser] = useState<User[]>([]);
  const { getUsers, addExpenseReports } = useFirebaseDB();
  const { user: authUser } = useAuth();
  const navigate = useNavigate();
  const availableRecipient = user.filter((u) => u.id !== authUser?.id);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleErrorMessage = (errMsg: any) => {
    console.error(errMsg);
  };

  useEffect(() => {
    getUsers(setUser);
  }, []);

  const handleSubmitButton = ({
    amount,
    description,
    recipient,
  }: ExpenseReportFormValue) => {
    if (authUser != null) {
      const operation: Operation = {
        id: "",
        description,
        payedAt: "",
        createdAt: formatDate(new Date()),
        amount,
        from: authUser,
        updatedAt: formatDate(new Date()),
        to: recipient,
        removedAt: null,
      };
      addExpenseReports(authUser.id, operation)
        .then(() => navigate(-1))
        .catch((err) => handleErrorMessage(err));
    }
  };

  const handleCancelButton = () => {
    navigate(-1);
  };

  if (availableRecipient.length > 0) {
    return (
      <FormSetReportComponent
        defaultValue={{
          description: "",
          amount: 0,
          recipient: availableRecipient[0],
        }}
        recipientList={availableRecipient}
        onCancel={handleCancelButton}
        onConfirm={handleSubmitButton}
        title={"Déclarer une note de frais"}
      />
    );
  } else {
    return <Loader text={"Récupération des données"} />;
  }
};

export default SetReportPage;
