import React from "react";
import { Button, Card, Typography } from "@mui/joy";
import { useAuth } from "../../services/AuthService";
import { Navigate } from "react-router-dom";
import "./loginPage.css";
import loginImage from "../../resources/img/login-alpine.webp";
import { FaGoogle } from "react-icons/fa";
import Loader from "../../components/utils/Loader";

export default function LoginPage() {
  const { signInRedirect, user } = useAuth();

  if (user === null) {
    return (
      <div className="login-page">
        <div className="login-pane">
          <Typography level="h1">Papy thune</Typography>
          <Card
            variant={"outlined"}
            sx={{
              padding: "1.5em",
              gap: "1em",
              textAlign: "center",
            }}
          >
            <Typography level={"h3"}>Connexion</Typography>
            <Button
              startDecorator={<FaGoogle />}
              variant={"solid"}
              size={"lg"}
              onClick={signInRedirect}
            >
              Se connecter avec Google
            </Button>
          </Card>
        </div>
        <div className="image-pane">
          <img alt={"moteur de voiture"} src={loginImage} />
        </div>
      </div>
    );
  } else if (user === undefined) {
    return <Loader text={"Connexion..."} />;
  } else {
    return <Navigate to={"/"} />;
  }
}
