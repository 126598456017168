export const platforms = [
  {
    value: "leboncoin",
    label: "Leboncoin",
  },
  {
    value: "ebay",
    label: "ebay",
  },
  {
    value: "encheres",
    label: "Vente aux enchères",
  },
];
