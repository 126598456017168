import * as React from "react";
import { Card, Container, Typography } from "@mui/joy";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFirestore } from "../../services/FirestoreService";
import { pipe } from "fp-ts/function";
import * as Task from "fp-ts/Task";
import * as Option from "fp-ts/Option";
import { ClassifiedAdDocument } from "../../types/Types";
import { ClassifiedAdViewComponent } from "./sub/ClassifiedAdViewComponent";

export function ClassifiedAdView() {
  const { docId } = useParams();
  const { getClassifiedAdById } = useFirestore();
  const [classifiedAd, setClassifiedAd] = useState<
    ClassifiedAdDocument | undefined
  >(undefined);

  useEffect(() => {
    if (docId != undefined) {
      pipe(
        getClassifiedAdById(docId),
        Task.map((maybeDoc) =>
          pipe(
            maybeDoc,
            Option.map((doc) => setClassifiedAd(doc))
          )
        )
      )().catch((err) => console.error("Cannot get document", err));
    }
  }, [docId]);

  return (
    <Container maxWidth={"lg"}>
      {classifiedAd != undefined ? (
        <ClassifiedAdViewComponent classifiedAd={classifiedAd} />
      ) : (
        <Card>
          <Typography>Chargement en cours.</Typography>
        </Card>
      )}
    </Container>
  );
}
