import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { FaExclamationTriangle } from "react-icons/fa";
import { OverridableStringUnion } from "@mui/types";
import { ColorPaletteProp } from "@mui/joy/styles/types";
import { ButtonPropsColorOverrides } from "@mui/joy/Button/ButtonProps";

type ConfirmationDialogProps = {
  visible: boolean;
  setVisible: (value: boolean) => void;
  message: string;
  confirmMessage: string;
  onConfirm: () => Promise<void>;
  color?: OverridableStringUnion<ColorPaletteProp, ButtonPropsColorOverrides>;
};

const ConfirmationDialog = ({
  onConfirm,
  confirmMessage,
  message,
  visible,
  setVisible,
  color,
}: ConfirmationDialogProps) => {
  const [processing, setProcessing] = useState(false);

  const handleConfirmButton = () => {
    if (!processing) {
      setProcessing(true);
      onConfirm()
        .then(() => setProcessing(false))
        .catch((err) => console.error(err))
        .finally(() => setVisible(false));
    }
  };

  const handleCloseButton = () => {
    if (!processing) {
      setVisible(false);
    }
  };

  return (
    <Modal open={visible} onClose={handleCloseButton}>
      <ModalDialog variant="outlined" role="alertdialog" size={"lg"}>
        <Typography level={"h4"} startDecorator={<FaExclamationTriangle />}>
          Confirmation
        </Typography>
        <Typography>{message}</Typography>
        <Box
          sx={{
            display: "flex",
            gap: "1em",
            justifyContent: "flex-end",
            marginTop: "1em",
          }}
        >
          <Button variant="plain" color="neutral" onClick={handleCloseButton}>
            Annuler
          </Button>
          <Button
            variant="solid"
            color={color ?? "danger"}
            onClick={() => handleConfirmButton()}
            startDecorator={processing ? <CircularProgress /> : null}
            disabled={processing}
          >
            {confirmMessage}
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  );
};

export default ConfirmationDialog;
