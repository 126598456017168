import * as React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Button, Typography } from "@mui/joy";
import { useFirestore } from "../../../services/FirestoreService";

type Props = {
  docId: string;
  visible: boolean;
  setVisible: (value: boolean) => void;
  removeDocIdOnUpdate: (docId: string) => void;
};

export function RemoveAdModal({
  docId,
  visible,
  setVisible,
  removeDocIdOnUpdate,
}: Props) {
  const { removeClassifiedAd } = useFirestore();

  const handleCancel = () => {
    setVisible(false);
  };

  const handleRemove = () => {
    removeClassifiedAd(docId).then(() => {
      removeDocIdOnUpdate(docId);
      setVisible(false);
    });
  };

  return (
    <Dialog
      id={"update-status-" + docId}
      maxWidth={"xs"}
      open={visible}
      onClose={handleCancel}
    >
      <DialogTitle>Suppression de l'annonce</DialogTitle>
      <DialogContent>
        <Typography level={"body-lg"}>
          Voulez vous supprimer cette annonce ?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant={"plain"} autoFocus onClick={handleCancel}>
          Annuler
        </Button>
        <Button color={"danger"} onClick={handleRemove}>
          Supprimer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
