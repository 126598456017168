import { Box, Card, CardCover } from "@mui/joy";
import * as React from "react";

type ImageViewerComponentProps = {
  imagesUrl: string[];
};
const ImageViewerComponent = ({ imagesUrl }: ImageViewerComponentProps) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: 2, width: "100%" }}>
      {imagesUrl.map((url, index) => (
        <Card key={index + url} sx={{ minHeight: "100px", minWidth: "100px" }}>
          <CardCover>
            <img loading={"lazy"} src={url} />
          </CardCover>
        </Card>
      ))}
    </Box>
  );
};

export default ImageViewerComponent;
