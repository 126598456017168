import React from "react";
import { Operation, UserType } from "../../../types/Types";
import { Avatar, Grid } from "@mui/material";
import { Typography } from "@mui/joy";
import { FaCheck, FaTimes } from "react-icons/fa";
import ActionMenu from "./ActionMenu";
import PayButton from "./PayButton";

type ExpenseReportItemDesktopProps = {
  operation: Operation;
  userToDisplay: UserType;
};
const ExpenseReportItemDesktop = ({
  operation,
  userToDisplay,
}: ExpenseReportItemDesktopProps) => {
  const displayUser =
    userToDisplay === UserType.emitter ? operation.from : operation.to;
  const defaultGridCss = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "1em",
  };

  return (
    <Grid container spacing={1} rowGap={"1em"} key={operation.id}>
      <Grid item sx={defaultGridCss} xs={3}>
        {displayUser.photoURL != null && <Avatar src={displayUser.photoURL} />}
        <Typography level={"body-lg"}>{displayUser.displayName}</Typography>
      </Grid>
      <Grid item sx={defaultGridCss} xs={6}>
        <Typography level={"body-lg"}>{operation.description}</Typography>
      </Grid>
      <Grid item sx={defaultGridCss} xs={1}>
        <Typography level={"body-md"}>{operation.amount} €</Typography>
      </Grid>
      <Grid item sx={defaultGridCss} xs={1}>
        <Typography
          level={"body-md"}
          color={operation.payedAt != "" ? "success" : "danger"}
        >
          {operation.payedAt != "" ? <FaCheck /> : <FaTimes />}
        </Typography>
      </Grid>
      <Grid item sx={{ ...defaultGridCss, justifyContent: "center" }} xs={1}>
        {operation.payedAt != "" ? (
          <Typography textAlign={"center"}>
            Payé le {operation.payedAt}
          </Typography>
        ) : userToDisplay === UserType.recipient ? (
          // For update and remove
          <ActionMenu operation={operation} />
        ) : (
          // For pay
          <PayButton expenseReportId={operation.id} />
        )}
      </Grid>
    </Grid>
  );
};

export default ExpenseReportItemDesktop;
