import * as React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useFirestore } from "../../../services/FirestoreService";
import { Box, Button, Checkbox, Typography } from "@mui/joy";
import { useDropzone } from "react-dropzone";
import { FaUpload } from "react-icons/fa";
import { useStorageService } from "../../../services/StorageService";
import { useState } from "react";
import {
  AddDeliveryVoucherClassifiedAdObject,
  ClassifiedAdStatusEnum,
} from "../../../types/Types";

type Props = {
  docId: string;
  visible: boolean;
  setVisible: (value: boolean) => void;
  removeDocIdOnUpdate: (docId: string) => void;
};

export function UploadDeliveryVoucherModal({
  docId,
  visible,
  setVisible,
  removeDocIdOnUpdate,
}: Props) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
    },
    maxFiles: 1,
  });
  const { uploadDeliveryVoucher } = useStorageService();
  const { updateClassifiedAd } = useFirestore();
  const [changeStatus, setChangeStatus] = useState(false);

  const handleClose = () => {
    setVisible(false);
  };

  const handleCheckBox = (value: boolean) => {
    setChangeStatus(value);
  };
  const handleOk = () => {
    uploadDeliveryVoucher(acceptedFiles[0])
      .then((voucherUrl) => {
        const newStatus = changeStatus
          ? ClassifiedAdStatusEnum.ATTENTE_ENVOI
          : ClassifiedAdStatusEnum.EN_LIGNE;
        const updateObject: AddDeliveryVoucherClassifiedAdObject = {
          status: newStatus,
          deliveryVoucher: voucherUrl,
        };
        return updateClassifiedAd(docId, updateObject);
      })
      .then(() => {
        console.log("Delivery voucher successfully added");
        if (changeStatus) {
          // If we change the status
          removeDocIdOnUpdate(docId);
        }
        setVisible(false);
      })
      .catch((err) => console.error("Can't add delivery voucher", err));
  };

  return (
    <Dialog
      id={"gp-next-status-" + docId}
      maxWidth={"xs"}
      open={visible}
      onClose={handleClose}
    >
      <DialogTitle id="alert-dialog-title">
        Chargement du bon d'envoi
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              border: "1px dashed #5fa9e0",
              borderRadius: "20px",
              minHeight: "6em",
            }}
            {...getRootProps({ className: "dropzone" })}
          >
            <input {...getInputProps()} />
            <Typography startDecorator={<FaUpload />} level={"body-md"}>
              Sélectionner le fichier
            </Typography>
          </Box>
          {acceptedFiles.map((value) => (
            <Typography key={value.webkitRelativePath} level={"body-md"}>
              {value.name}
            </Typography>
          ))}
        </Box>
        <Checkbox
          checked={changeStatus}
          onChange={(event) => handleCheckBox(event.target.checked)}
          label={
            <Typography level={"body-lg"}>
              Passer le status à{" "}
              <Typography color={"primary"}>En attente d'envoi</Typography>
            </Typography>
          }
        />
      </DialogContent>
      <DialogActions>
        <Box
          sx={{ display: "flex", flexDirection: "row", gap: 1, marginTop: 2 }}
        >
          <Button variant={"plain"} autoFocus onClick={handleClose}>
            Annuler
          </Button>
          <Button onClick={handleOk} disabled={acceptedFiles.length === 0}>
            Mettre à jour
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
