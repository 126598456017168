import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  GoogleAuthProvider,
  User,
  onAuthStateChanged,
  signOut as firebaseSignOut,
  signInWithRedirect,
  getAuth,
  getRedirectResult,
} from "firebase/auth";
import { firebaseAuth, googleAuthProvider } from "../firebase/FirebaseService";
import { addUserInformation } from "./DatabaseService";
import { User as UserDB } from "../types/Types";
import { FirebaseError } from "firebase/app";

const AuthContext = createContext<AuthType>(null!);

export type AuthType = {
  user: UserDB | null | undefined;
  signInRedirect: () => void;
  signOut: () => void;
};
const auth = getAuth();
getRedirectResult(auth)
  .then((result) => {
    if (result != null) {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      if (credential) {
        const token = credential.accessToken;
        console.log(token);
      }

      const userDb: UserDB = {
        id: result.user.uid,
        displayName: result.user.displayName ?? "",
        email: result.user.email ?? "",
        photoURL: result.user.photoURL,
      };
      addUserInformation(userDb)
        .then(() => console.log("User information added successfully"))
        .catch((error) => console.error(error));
    }
  })
  .catch((error: FirebaseError) => {
    // Handle Errors here.
    console.error(JSON.stringify(error));
  });

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [userDB, setUserDB] = useState<UserDB | null | undefined>(undefined);

  const userToUserDB = (user: User): UserDB => {
    return {
      id: user.uid,
      displayName: user.displayName ?? "",
      email: user.email ?? "",
      photoURL: user.photoURL,
    };
  };

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, (user) => {
      console.log("state changed");
      const value = user != null ? userToUserDB(user) : null;
      setUserDB(value);
    });
  }, []);

  const signInRedirect = () =>
    signInWithRedirect(firebaseAuth, googleAuthProvider);

  const signOut = () => {
    firebaseSignOut(firebaseAuth)
      .then(() => {
        console.log("sign out successfully");
      })
      .catch((error) => {
        console.error("Error when logout ", error);
      });
  };

  const value = useMemo(() => {
    return {
      user: userDB,
      signOut,
      signInRedirect,
    };
  }, [userDB]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
