import React from "react";
import { Operation, UserType } from "../../../types/Types";
import { Box, Card, Typography } from "@mui/joy";
import { FaCheck } from "react-icons/fa";
import ActionMenu from "./ActionMenu";
import PayButton from "./PayButton";
import { DateTime } from "luxon";

type ExpenseReportItemMobileProps = {
  operation: Operation;
  userToDisplay: UserType;
};
const ExpenseReportMobile = ({
  operation,
  userToDisplay,
}: ExpenseReportItemMobileProps) => {
  const displayUser =
    userToDisplay === UserType.emitter ? operation.from : operation.to;
  const createdDate = DateTime.fromFormat(operation.createdAt, "dd/MM/yyyy");
  const payedAtDate = DateTime.fromFormat(operation.payedAt, "dd/MM/yyyy");

  return (
    <Card sx={{ flexDirection: "row", gap: "0.5em", alignItems: "center" }}>
      <Box sx={{ flex: "1", textAlign: "center" }}>
        <Typography level={"body-xs"}>{createdDate.toFormat("LLL")}</Typography>
        <Typography level={"body-sm"}>{createdDate.toFormat("dd")}</Typography>
      </Box>
      <Box
        sx={{
          flex: "3",
          flexDirection: "column",
          gap: "0.5em",
          alignItems: "center",
        }}
      >
        <Typography sx={{ flex: "1" }} level={"body-xs"}>
          {displayUser.displayName}
        </Typography>
        <Typography sx={{ flex: "2" }} level={"body-md"}>
          {operation.description}
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={{ flex: "1" }}
          textColor={operation.payedAt != "" ? "neutral.500" : "danger.500"}
          level={"body-sm"}
        >
          {operation.amount} €
        </Typography>
      </Box>
      <Box
        sx={{
          flex: "1",
          flexDirection: "column",
          gap: "0.5em",
          textAlign: "center",
        }}
      >
        {operation.payedAt != "" && (
          <>
            <Typography level={"body-xs"} color={"success"}>
              <FaCheck />
            </Typography>
            <Typography level={"body-xs"} color={"success"}>
              {payedAtDate.toFormat("dd LLL yyyy")}
            </Typography>
          </>
        )}
        <Box sx={{ flex: "2" }}>
          {operation.payedAt === "" &&
            (userToDisplay === UserType.recipient ? (
              // For update and remove
              <ActionMenu operation={operation} />
            ) : (
              // For pay
              <PayButton expenseReportId={operation.id} />
            ))}
        </Box>
      </Box>
    </Card>
  );
};

export default ExpenseReportMobile;
