import { Timestamp } from "firebase/firestore";

export type Operation = {
  id: string;
  from: User;
  to: User;
  description: string;
  amount: number;
  payedAt: string;
  createdAt: string;
  updatedAt: string | null;
  removedAt: string | null;
};

export type ExpenseReportFormValue = {
  recipient: User;
  amount: number;
  description: string;
};

export type User = {
  id: string;
  displayName: string;
  email: string;
  photoURL: string | null;
};

export type PayTo = {
  id: string;
  payedAt: string | null;
};

export enum UserType {
  emitter,
  recipient,
}

export enum ClassifiedAdStatusEnum {
  CREATION = 1,
  EN_LIGNE,
  ATTENTE_ENVOI,
  ENVOI_EN_COURS,
  VENDU,
}

export const classifiedAdStatusEnumValues: ClassifiedAdStatusEnum[] = [
  ClassifiedAdStatusEnum.CREATION,
  ClassifiedAdStatusEnum.EN_LIGNE,
  ClassifiedAdStatusEnum.ATTENTE_ENVOI,
  ClassifiedAdStatusEnum.ENVOI_EN_COURS,
  ClassifiedAdStatusEnum.VENDU,
];

export enum PlatformEnum {
  LEBONCOIN = "leboncoin",
  EBAY = "ebay",
  ENCHERES = "encheres",
}

export type ClassifiedAdDocument = {
  id: string;
  title: string;
  description: string;
  price: number;
  photoUrl: string[];
  weight: number;
  platform: PlatformEnum;
  deliveryVoucher?: string;
  status: ClassifiedAdStatusEnum;
  createdDate: Timestamp;
  soldDate: Timestamp | null;
  updatedAt: Timestamp | undefined;
  receivedBy: string | null;
};

export interface UpdateClassifiedAdObject {}

export interface UpdateClassifiedAddObject extends UpdateClassifiedAdObject {
  title: string;
  description: string;
  price: number;
  weight: number;
  platform: PlatformEnum;
  soldDate: Timestamp | null;
}

export interface AddDeliveryVoucherClassifiedAdObject
  extends UpdateClassifiedAdObject {
  status: ClassifiedAdStatusEnum;
  deliveryVoucher: string;
}
