import { ClassifiedAdStatusEnum } from "../types/Types";

export const getStatusLabel = (status: ClassifiedAdStatusEnum): string => {
  switch (status) {
    case ClassifiedAdStatusEnum.CREATION:
      return "Non publié";
    case ClassifiedAdStatusEnum.EN_LIGNE:
      return "En ligne";
    case ClassifiedAdStatusEnum.ATTENTE_ENVOI:
      return "En attente d'envoi";
    case ClassifiedAdStatusEnum.ENVOI_EN_COURS:
      return "En cours de livraison";
    case ClassifiedAdStatusEnum.VENDU:
      return "Vendu";
    default:
      return "-";
  }
};

export const getStatusFromId = (statusId: number): ClassifiedAdStatusEnum => {
  switch (statusId) {
    case 1:
      return ClassifiedAdStatusEnum.CREATION;
    case 2:
      return ClassifiedAdStatusEnum.EN_LIGNE;
    case 3:
      return ClassifiedAdStatusEnum.ATTENTE_ENVOI;
    case 4:
      return ClassifiedAdStatusEnum.ENVOI_EN_COURS;
    case 5:
      return ClassifiedAdStatusEnum.VENDU;
    default:
      throw Error(`status ${statusId} not exists`);
  }
};
