import React from "react";
import { Card, Link, Typography, Skeleton } from "@mui/joy";
import { Link as RouterLink } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa";
import CountUp from "react-countup";

type GenericCardProps = {
  name: string;
  value?: number;
  suffix?: string;
  link?: string;
  animated?: boolean;
  isLoading?: boolean;
};
export const GenericCard = ({
  name,
  value,
  suffix,
  link,
  animated,
  isLoading = false,
}: GenericCardProps) => {
  const suffixStr = suffix !== undefined ? ` ${suffix}` : "";

  return (
    <Card
      sx={(theme) => ({
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "space-between",
        backgroundColor: theme.vars.palette.background.level1,
      })}
      variant={"outlined"}
    >
      {isLoading ? (
        <Skeleton variant="text" level="h2" animation={"wave"} />
      ) : (
        <Typography level={"h2"}>
          {value !== undefined ? (
            <CountUp
              duration={animated ? 2 : 0}
              start={0}
              end={value}
              useEasing
              suffix={suffixStr}
            />
          ) : (
            `- ${suffixStr}`
          )}
        </Typography>
      )}

      <Link
        overlay
        disabled={!link}
        component={RouterLink}
        to={link ?? ""}
        underline="none"
        variant="plain"
      >
        <Typography
          level={"title-sm"}
          endDecorator={link && <FaExternalLinkAlt size={12} />}
        >
          {name}
        </Typography>
      </Link>
    </Card>
  );
};
