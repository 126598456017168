import React from "react";
import { useAuth } from "../AuthService";
import { Navigate } from "react-router-dom";

export const AuthRequired = ({ children }: { children: JSX.Element }) => {
  const { user } = useAuth();
  if (user === null) {
    console.log("No auth - redirect");
    return <Navigate to={"/login"} />;
  } else if (user === undefined) {
    // Don't know if connected or not, so wait
    return <></>;
  }

  return children;
};
