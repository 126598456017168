import React from "react";
import { Operation, UserType } from "../../../types/Types";
import ExpenseReportItemDesktop from "./ExpenseReportItemDesktop";
import { BrowserView, MobileView } from "react-device-detect";
import ExpenseReportItemMobile from "./ExpenseReportItemMobile";
import { Box } from "@mui/joy";

type ExpenseReportsListProps = {
  operations: Operation[];
  userToDisplay: UserType;
};
const ExpenseReportsList = ({
  operations,
  userToDisplay,
}: ExpenseReportsListProps) => {
  return (
    <>
      <BrowserView>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1em" }}>
          {operations.map((operation) => (
            <ExpenseReportItemDesktop
              key={operation.id}
              operation={operation}
              userToDisplay={userToDisplay}
            />
          ))}
        </Box>
      </BrowserView>
      <MobileView>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1em" }}>
          {operations.map((operation) => (
            <ExpenseReportItemMobile
              key={operation.id}
              operation={operation}
              userToDisplay={userToDisplay}
            />
          ))}
        </Box>
      </MobileView>
    </>
  );
};

export default ExpenseReportsList;
