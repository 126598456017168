export const firebaseConfig = {
  apiKey: "AIzaSyAfboBAskxKQgck67kO0N-pUnq7EcAbghs",
  authDomain: "papythune.aguiheneuf.fr",
  databaseURL:
    "https://papythune-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "papythune",
  storageBucket: "papythune.appspot.com",
  messagingSenderId: "869719771320",
  appId: "1:869719771320:web:ec8d9742b51c411a13cc4d",
  measurementId: "G-Q1HDYVEJ5L",
};
