import { SumByUserItem } from "../types/AdMetricsDto";

const baseUrl =
  "https://europe-west1-papythune.cloudfunctions.net/classifiedAdMetrics";

export const getSumByUser = (): Promise<SumByUserItem[]> => {
  return fetch(baseUrl + "/receivedByUser").then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json() as Promise<SumByUserItem[]>;
  });
};
