import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFirebaseDB } from "../../../services/DatabaseService";
import { ExpenseReportFormValue, Operation } from "../../../types/Types";
import { useAuth } from "../../../services/AuthService";
import * as Option from "fp-ts/Option";
import * as Task from "fp-ts/Task";
import FormSetReportComponent from "../../../components/forms/FormSetReportComponent";
import { pipe } from "fp-ts/function";
import Loader from "../../../components/utils/Loader";
import { formatDate } from "../../../utils/DateUtils";

const UpdateReportPage = () => {
  const { expenseReportId } = useParams();
  const { user } = useAuth();
  const [operation, setOperation] = useState<Option.Option<Operation>>(
    Option.none
  );
  const { getSingleOperation, updateExpenseReport } = useFirebaseDB();
  const navigate = useNavigate();

  useEffect(() => {
    if (user != null && expenseReportId != undefined) {
      getSingleOperation(user.id, expenseReportId)()
        .then((maybeOperation) => setOperation(maybeOperation))
        .catch((err) => console.error("Error while fetching operation", err));
    }
  }, [user]);

  const handleOnCancel = () => {
    navigate(-1);
  };

  const handleOnConfirm = ({ amount, description }: ExpenseReportFormValue) => {
    if (Option.isSome(operation)) {
      pipe(
        operation,
        Option.map((originalOp) => {
          const updatedAt = formatDate(new Date());
          const newOperation = {
            ...originalOp,
            amount,
            description,
            updatedAt,
          };
          return () => updateExpenseReport(newOperation);
        }),
        Option.sequence(Task.ApplicativeSeq)
      )()
        .then(() => console.log("successful update"))
        .catch((err) => console.error("Error while updating report", err))
        .finally(() => navigate("/expenseReport"));
    }
  };

  return pipe(
    operation,
    Option.map((o) => {
      const formValue: ExpenseReportFormValue = {
        amount: o.amount,
        description: o.description,
        recipient: o.to,
      };
      return (
        <FormSetReportComponent
          title={"Modifier une note de frais"}
          defaultValue={formValue}
          recipientList={[formValue.recipient]}
          onCancel={handleOnCancel}
          onConfirm={handleOnConfirm}
        />
      );
    }),
    Option.getOrElse(() => <Loader text={"Chargement..."} />)
  );
};

export default UpdateReportPage;
