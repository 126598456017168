import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { firebaseStorage } from "../firebase/FirebaseService";

/**
 * Upload file at specified directory
 * @param file
 * @param onProgress
 * @param directory
 */
const uploadFile = (
  file: File,
  directory: string,
  onProgress?: (progress: number) => void
): Promise<string> => {
  const fileName = uuidv4() + "-" + file.name;
  const storageRef = ref(firebaseStorage, `${directory}/${fileName}`);

  const uploadTask = uploadBytesResumable(storageRef, file);

  const ret = new Promise<string>((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        if (onProgress) {
          const progression =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          onProgress(progression);
        }
      },
      (error) => {
        console.error("error while uploading file", error);
        reject(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => resolve(url));
      }
    );
  });

  return ret;
};

const uploadPhoto = (
  file: File,
  onProgress?: (progress: number) => void
): Promise<string> => uploadFile(file, "photo", onProgress);

const uploadDeliveryVoucher = (
  file: File,
  onProgress?: (progress: number) => void
): Promise<string> => uploadFile(file, "deliveryVoucher", onProgress);

export const useStorageService = () => {
  return { uploadPhoto, uploadDeliveryVoucher };
};
