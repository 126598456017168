import * as React from "react";
import { AspectRatio, Container } from "@mui/joy";
import ClassifiedAdByUserIndicatorComponent from "./components/ClassifiedAdByUserIndicatorComponent";
import { useHomePageMetrics } from "./hooks/useHomePageMetrics";
import * as Option from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { GenericCardView } from "./components/GenericCardView";

const HomePage = () => {
  const { data, loading: isLoading } = useHomePageMetrics();

  return (
    <Container
      maxWidth={"md"}
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        gap: "1em",
        paddingBottom: "3em",
        paddingTop: "1em",
        backgroundColor: theme.vars.palette.background.level2,
      })}
    >
      {pipe(
        data,
        Option.map((metrics) => {
          const total = metrics.totalSold - metrics.purchasedStuffTotal;
          return {
            ...metrics,
            total,
          };
        }),
        Option.fold(
          () => <GenericCardView isLoading />,
          ({ total, countSold, onSaleItems }) => (
            <GenericCardView
              countSold={countSold}
              onSaleItems={onSaleItems}
              total={total}
              isLoading={isLoading}
            />
          )
        )
      )}
      <ClassifiedAdByUserIndicatorComponent />
      <AspectRatio objectFit={"cover"} style={{ borderRadius: "8px" }}>
        <img
          alt={"Picsou"}
          src={
            "https://vl-media.fr/wp-content/uploads/2022/03/Picsou-75-ans-anniversaire-Disney-Carl-Barks-La-Bande-a-Picsou-Picsou-Magazine-1024x694.jpg"
          }
        />
      </AspectRatio>
    </Container>
  );
};

export default HomePage;
