import * as React from "react";
import { Box, Chip } from "@mui/joy";
import {
  ClassifiedAdStatusEnum,
  classifiedAdStatusEnumValues,
} from "../../../types/Types";
import { getStatusLabel } from "../../../utils/ClassifiedAdStatusUtils";

type Props = {
  selectedStatus: ClassifiedAdStatusEnum;
  onStatusChange: (status: ClassifiedAdStatusEnum) => void;
};

export function ListStatusComponent({ selectedStatus, onStatusChange }: Props) {
  const handleChipClick = (status: ClassifiedAdStatusEnum) =>
    onStatusChange(status);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "no-wrap",
        gap: "0.5em",
        overflow: "scroll",
        paddingBottom: "1em",
      }}
    >
      {classifiedAdStatusEnumValues.map((status) => (
        <Chip
          key={status.valueOf()}
          color={"neutral"}
          variant={selectedStatus === status ? "solid" : "outlined"}
          onClick={() => handleChipClick(status)}
        >
          {getStatusLabel(status)}
        </Chip>
      ))}
    </Box>
  );
}
