import React from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardOverflow,
  FormControl,
  FormLabel,
  Input,
  ListItemDecorator,
  Option,
  Select,
  Textarea,
  Typography,
} from "@mui/joy";
import { FaMoneyBillWave, FaPaperPlane } from "react-icons/fa";
import { ExpenseReportFormValue, User } from "../../types/Types";
import { useForm, Controller, SubmitHandler } from "react-hook-form";

type FormSetReportComponentProps = {
  defaultValue: ExpenseReportFormValue;
  recipientList: User[];
  onCancel: () => void;
  onConfirm: (result: ExpenseReportFormValue) => void;
  title: string;
};

type Inputs = {
  amount: number;
  description: string;
  recipient: string;
};

const FormSetReportComponent = ({
  defaultValue,
  onCancel,
  onConfirm,
  title,
  recipientList,
}: FormSetReportComponentProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      description: defaultValue.description,
      amount: defaultValue.amount === 0 ? undefined : defaultValue.amount,
      recipient: defaultValue.recipient.id,
    },
  });

  const getRecipientFromId = (recipientId: string) => {
    return recipientList.filter((recipient) => recipient.id === recipientId)[0];
  };

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const newValue: ExpenseReportFormValue = {
      amount: data.amount,
      description: data.description,
      recipient: getRecipientFromId(data.recipient),
    };
    onConfirm(newValue);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: { xs: "1em", lg: "5em" },
      }}
    >
      <Card
        sx={{ display: "flex", flexDirection: "column", gap: "3em" }}
        variant="outlined"
      >
        <Typography level={"h1"}>{title}</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            <Controller
              name={"recipient"}
              control={control}
              rules={{ required: true }}
              defaultValue={""}
              render={({ field: { value, onChange } }) => (
                <Select
                  disabled={recipientList.length == 1}
                  value={value}
                  onChange={(_event, newValue) => {
                    console.log(newValue);
                    onChange(newValue);
                  }}
                >
                  {recipientList.map((u) => (
                    <Option key={u.id} value={u.id}>
                      <ListItemDecorator sx={{ marginRight: "0.5em" }}>
                        <Avatar size={"sm"} src={u.photoURL ?? ""} />
                      </ListItemDecorator>
                      {u.displayName}
                    </Option>
                  ))}
                </Select>
              )}
            />
            <FormControl>
              <FormLabel>Montant</FormLabel>
              <Input
                placeholder={"Exemple: 200€"}
                startDecorator={<FaMoneyBillWave />}
                contextMenu={
                  errors.amount &&
                  "Le montant est obligatoire et doit être supérieur à 0€"
                }
                error={errors.amount != null}
                type={"number"}
                inputMode={"numeric"}
                {...register("amount", { required: true, min: 1 })}
              />
            </FormControl>
            <Textarea
              {...register("description")}
              minRows={4}
              placeholder="Décrivez les dépenses"
            />
            <Box sx={{ height: "1px", bgcolor: "divider" }} />
            <CardOverflow
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                color={"neutral"}
                variant={"outlined"}
                onClick={onCancel}
                type={"button"}
              >
                Annuler
              </Button>
              <Button
                color={"primary"}
                startDecorator={<FaPaperPlane />}
                type={"submit"}
              >
                Enregistrer
              </Button>
            </CardOverflow>
          </Box>
        </form>
      </Card>
    </Box>
  );
};

export default FormSetReportComponent;
