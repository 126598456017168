import * as React from "react";
import { Container, Typography } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import ClassifiedAdForm from "./form/ClassifiedAdForm";

const AddView = () => {
  const navigate = useNavigate();

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth={"md"} sx={{ paddingBottom: "5em" }}>
      <Typography level={"h1"}>Créer une annonce</Typography>
      <ClassifiedAdForm isNewClassifiedAdd={true} onCancel={onCancel} />
    </Container>
  );
};

export default AddView;
