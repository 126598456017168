import * as React from "react";
import {
  Box,
  Button,
  Container,
  Drawer,
  Link,
  Sheet,
  Stack,
  Typography,
} from "@mui/joy";
import AccountMenu from "../AccountMenu";
import { BrowserView, MobileView } from "react-device-detect";
import { Outlet, useNavigate } from "react-router-dom";
import { useState } from "react";
import { FaTools, FaBars, FaHome, FaPiggyBank } from "react-icons/fa";
import { DrawerButton } from "./DrawerButton";

type NavigationBarProps = {
  handleHomeLink: () => void;
  handleClassAdLink: () => void;
  handlePurchasedStuffLink: () => void;
};

const DesktopNavigationBar = ({
  handleHomeLink,
  handleClassAdLink,
  handlePurchasedStuffLink,
}: NavigationBarProps) => (
  <>
    <nav>
      <Container
        maxWidth={"lg"}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1em",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ flex: "3", justifyContent: "start" }}>
          <Typography level={"title-lg"}>Papy thune</Typography>
        </Box>
        <Box
          sx={{
            flex: "2",
            display: "flex",
            flexDirection: "row",
            gap: "1em",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <Link onClick={handleHomeLink}>Accueil</Link>
          <Link onClick={handleClassAdLink}>Annonces</Link>
          <Link onClick={handlePurchasedStuffLink}>Achats</Link>
          <AccountMenu />
        </Box>
      </Container>
    </nav>
    <main>
      <Box sx={{ marginTop: "2em" }}>
        <Outlet />
      </Box>
    </main>
  </>
);

const MobileNavigationBar = ({
  handleHomeLink,
  handleClassAdLink,
  handlePurchasedStuffLink,
}: NavigationBarProps) => {
  const [showDrawer, setShowDrawer] = useState(false);

  const toggleOnDrawer = () => setShowDrawer(true);
  const toggleOffDrawer = () => setShowDrawer(false);

  return (
    <Sheet
      sx={(theme) => ({
        minHeight: "100vh",
        backgroundColor: theme.vars.palette.background.level1,
      })}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
          background: theme.vars.palette.background.level1,
        })}
      >
        <Button onClick={toggleOnDrawer} variant={"plain"} color={"neutral"}>
          <FaBars />
        </Button>
        <Typography level={"h4"}>Papy thune</Typography>
      </Box>
      <Drawer
        size={"md"}
        variant={"plain"}
        anchor={"left"}
        open={showDrawer}
        onClose={toggleOffDrawer}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
          }}
        >
          <AccountMenu />
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <DrawerButton
              label={"Accueil"}
              onClick={handleHomeLink}
              toggleOffDrawer={toggleOffDrawer}
              icon={<FaHome size={20} />}
            />
            <DrawerButton
              label={"Annonces"}
              onClick={handleClassAdLink}
              toggleOffDrawer={toggleOffDrawer}
              icon={<FaPiggyBank size={20} />}
            />
            <DrawerButton
              label={"Le matos"}
              onClick={handlePurchasedStuffLink}
              toggleOffDrawer={toggleOffDrawer}
              icon={<FaTools size={20} />}
            />
          </Stack>
        </Box>
      </Drawer>
      <Outlet />
    </Sheet>
  );
};

const NavigationBar = () => {
  const navigate = useNavigate();

  const handlePurchasedStuff = () => {
    navigate("/purchasedStuff");
  };

  const handleClassAd = () => {
    navigate("/classifiedAds/1");
  };

  const handleHome = () => {
    navigate("/");
  };

  return (
    <>
      <BrowserView>
        <DesktopNavigationBar
          handleHomeLink={handleHome}
          handleClassAdLink={handleClassAd}
          handlePurchasedStuffLink={handlePurchasedStuff}
        />
      </BrowserView>
      <MobileView>
        <MobileNavigationBar
          handleHomeLink={handleHome}
          handleClassAdLink={handleClassAd}
          handlePurchasedStuffLink={handlePurchasedStuff}
        />
      </MobileView>
    </>
  );
};

export default NavigationBar;
