import * as React from "react";
import { Button, Typography } from "@mui/joy";

type Props = {
  label: string;
  icon: React.ReactNode;
  toggleOffDrawer: () => void;
  onClick: () => void;
};
export const DrawerButton = ({
  icon,
  label,
  onClick,
  toggleOffDrawer,
}: Props) => {
  const handleClick = () => {
    onClick();
    toggleOffDrawer();
  };
  return (
    <Button
      sx={{ width: "100%", justifyContent: "start" }}
      startDecorator={icon}
      variant={"plain"}
      color={"neutral"}
      onClick={handleClick}
    >
      <Typography level={"h4"}>{label}</Typography>
    </Button>
  );
};
