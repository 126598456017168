import { PurchasedStuff } from "../application/PurchasedStuff";
import { PurchasedStuffCard } from "./PurchasedStuffCard";

type Props = {
  purchasedStuff: PurchasedStuff[] | undefined;
};
export const TotalPurchasedStuff = ({ purchasedStuff }: Props) => {
  const formattedValue = purchasedStuff
    ? purchasedStuff.length.toString()
    : "-";

  return (
    <PurchasedStuffCard title={"Le matos"} formattedValue={formattedValue} />
  );
};
