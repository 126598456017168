import * as React from "react";
import { ClassifiedAdDocument } from "../../../types/Types";
import { Box, Card, CardOverflow, Divider, Typography } from "@mui/joy";
import ImageViewComponent from "./form/sub/ImageViewComponent";
import { FaWeightHanging, FaMoneyBillAlt } from "react-icons/fa";

type Props = {
  classifiedAd: ClassifiedAdDocument;
};

export function ClassifiedAdViewComponent({ classifiedAd }: Props) {
  return (
    <Card variant={"outlined"}>
      <Typography level={"h3"}>{classifiedAd.title}</Typography>
      <ImageViewComponent imagesUrl={classifiedAd.photoUrl} />
      <Typography level={"body-lg"}>{classifiedAd.description}</Typography>
      <Divider />
      <CardOverflow
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "flex-end",
          bgcolor: "background.level1",
        }}
      >
        <Box>
          <Typography level={"body-sm"} startDecorator={<FaWeightHanging />}>
            {classifiedAd.weight} Kg
          </Typography>
        </Box>
        <Box>
          <Typography level={"body-sm"} startDecorator={<FaMoneyBillAlt />}>
            {classifiedAd.price} €
          </Typography>
          <Typography level={"body-sm"} startDecorator={<FaMoneyBillAlt />}>
            {classifiedAd.price} €
          </Typography>
        </Box>
      </CardOverflow>
    </Card>
  );
}
