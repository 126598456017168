import * as React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ClassifiedAdStatusEnum } from "../../../types/Types";
import { useFirestore } from "../../../services/FirestoreService";
import { Box, Button, Typography } from "@mui/joy";
import { getStatusLabel } from "../../../utils/ClassifiedAdStatusUtils";
import SelectUserComponent from "../../forms/SelectUserComponent";
import { useState } from "react";

type Props = {
  nextStatus: ClassifiedAdStatusEnum;
  docId: string;
  visible: boolean;
  setVisible: (value: boolean) => void;
  removeDocIdOnUpdate: (docId: string) => void;
};

export function GoNextStatusModal({
  nextStatus,
  docId,
  removeDocIdOnUpdate,
  setVisible,
  visible,
}: Props) {
  const { updateStatus } = useFirestore();
  const [user, setUser] = useState<string | null>(null);

  const handleClose = () => {
    setVisible(false);
  };

  const handleOk = () => {
    const maybeReceivedBy = user != null ? user : undefined;

    updateStatus(docId, nextStatus, maybeReceivedBy)
      .then(() => {
        console.log("update successfully");
        setVisible(false);
        removeDocIdOnUpdate(docId);
      })
      .catch((err) => console.error("cannot update status", err));
  };

  return (
    <Dialog
      id={"gp-next-status-" + docId}
      maxWidth={"xs"}
      open={visible}
      onClose={handleClose}
    >
      <DialogTitle id="alert-dialog-title">
        Voulez-vous mettre à jour le statut de cette annonce ?
      </DialogTitle>
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", gap: "1em" }}
      >
        <Typography level={"body-lg"}>
          Passer au statut
          <Typography color={"primary"}>
            {getStatusLabel(nextStatus)}
          </Typography>
        </Typography>
        {nextStatus === ClassifiedAdStatusEnum.VENDU && (
          <SelectUserComponent
            placeholder={"Vendu par..."}
            value={user}
            onChange={setUser}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Box
          sx={{ display: "flex", flexDirection: "row", gap: 1, marginTop: 2 }}
        >
          <Button variant={"plain"} autoFocus onClick={handleClose}>
            Annuler
          </Button>
          <Button onClick={handleOk}>Mettre à jour</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
