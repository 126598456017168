import { PurchasedStuff } from "../application/PurchasedStuff";
import { firebaseFirestore } from "../../../firebase/FirebaseService";
import { addDoc, collection } from "firebase/firestore";

const FIRESTORE_COLLECTION = "purchasedStuff";
export const useAddPurchasedStuff = () => {
  const addPurchasedStuff = async (
    purchasedStuff: Omit<PurchasedStuff, "updatedAt" | "id">
  ) =>
    addDoc(collection(firebaseFirestore, FIRESTORE_COLLECTION), {
      ...purchasedStuff,
      price: +purchasedStuff.price,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    });

  return {
    addPurchasedStuff,
  };
};
