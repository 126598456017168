import * as React from "react";
import {
  ClassifiedAdStatusEnum,
  classifiedAdStatusEnumValues,
} from "../../../types/Types";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Box, Button, Radio, RadioGroup } from "@mui/joy";
import {
  getStatusFromId,
  getStatusLabel,
} from "../../../utils/ClassifiedAdStatusUtils";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useFirestore } from "../../../services/FirestoreService";
import SelectUserComponent from "../../forms/SelectUserComponent";

type Props = {
  actualStatus: ClassifiedAdStatusEnum;
  docId: string;
  visible: boolean;
  setVisible: (value: boolean) => void;
  removeDocIdOnUpdate: (docId: string) => void;
};

type Input = {
  status: ClassifiedAdStatusEnum;
  receivedBy: string | null;
};

export function UpdateStatusModal({
  actualStatus,
  docId,
  visible,
  setVisible,
  removeDocIdOnUpdate,
}: Props) {
  const { updateStatus } = useFirestore();

  const { control, handleSubmit, watch } = useForm<Input>({
    defaultValues: {
      status: actualStatus,
    },
  });
  const watchSelectedStatus = getStatusFromId(+watch("status", actualStatus));

  const handleCancel = () => {
    setVisible(false);
  };

  const onSubmit: SubmitHandler<Input> = (data) => {
    console.log(
      `update status : ${data.status}, receivedBy ${data.receivedBy}`
    );
    const maybeUser = data.receivedBy != null ? data.receivedBy : undefined;

    updateStatus(docId, data.status, maybeUser)
      .then(() => {
        console.log("update successfully");
        setVisible(false);
        removeDocIdOnUpdate(docId);
      })
      .catch((err) => console.error("Error while updating document", err));
  };

  return (
    <Dialog
      id={"update-status-" + docId}
      maxWidth={"xs"}
      open={visible}
      onClose={handleCancel}
    >
      <DialogTitle>Mettre à jour le statut</DialogTitle>
      <DialogContent dividers>
        <form id={"radio-form"} onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Controller
              name={"status"}
              control={control}
              render={({ field: { value, onChange } }) => (
                <RadioGroup
                  value={value}
                  onChange={(event) => onChange(event.target.value)}
                >
                  {classifiedAdStatusEnumValues.map((value) => (
                    <Radio
                      key={value}
                      value={value.valueOf()}
                      label={getStatusLabel(value)}
                    />
                  ))}
                </RadioGroup>
              )}
            />
            {watchSelectedStatus === ClassifiedAdStatusEnum.VENDU && (
              <Controller
                name={"receivedBy"}
                control={control}
                defaultValue={null}
                render={({ field: { value, onChange } }) => (
                  <SelectUserComponent
                    placeholder={"Vendu par..."}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            )}
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "row", gap: 1, marginTop: 2 }}
          >
            <Button variant={"plain"} autoFocus onClick={handleCancel}>
              Annuler
            </Button>
            <Button
              type={"submit"}
              disabled={watchSelectedStatus === actualStatus}
            >
              Mettre à jour
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}
